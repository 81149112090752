import { HttpErrorInterceptor } from './../core/http-error.interceptor';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpWebService } from '../../app/core/http-web.service';
import { FormBuilder, Validators, Validator } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthServiceReg } from '../../app/register/auth.service';
import { AlertService } from "../../app/shared/layout/alerts/alert.service";
import { NotificationService } from '../../app/shared/utils/notification.service';
import { DomSanitizer, SafeUrl, ɵDomSanitizerImpl, SafeHtml } from '@angular/platform-browser';
import { dateFormat } from 'highcharts';
import { DateFormatter } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { template } from '@angular/core/src/render3'; 
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';

defineLocale('es', esLocale);
declare var $;

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
  
  @ViewChild('template') modalTemplate: TemplateRef<any>;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-dialog-45',
  };
  modalRef: BsModalRef;
  myDate = new Date();
  public pattern: any;
  public action: string = '';
  public password1: string;
  public password2: string;
  public nombre: string;
  public correo_electronico: string;
  public password: string;
  public confirmPassword: string;
  public privacidad: any;
  public validar: string;
  public descripcion: string;
  public apellido_paterno: string;
  public apellido_materno: string;
  public telefono: string;
  public fechaNacimiento: any;
  public disabled: boolean = false;
  public nacimiento: any;
  error: any;
  radioModel = 'Cliente';

  sanitizer: any;
  name = 'Angular';
  imagen; url2;
  isModalShown = false;
  bsModalRef: BsModalRef;
  verifica: any;

  constructor(
    private HttpWebService: HttpWebService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthServiceReg,
    public http: HttpClient,
    private alertService: AlertService,
    private notificationService: NotificationService,
    public _DomSanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private localeService: BsLocaleService
  ) {
    this.sanitizer = _DomSanitizer;
    this.pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.{8,}$)/;


  }

  openModal(template: TemplateRef<any>) {
    /* this.modalRef = this.modalService.show(template, this.config); */
    this.modalRef = this.bsModalRef = this.modalService.show(template, this.config);
  }

  ngOnInit() {
    this.localeService.use('es');
    /* this.imagen = 'https://via.placeholder.com/100x100'; */
    this.fechaNacimiento = new Date();
  }


  /*   changeListener($event): void {
      this.readThis($event.target);
    } */

  /*   readThis(inputValue: any): void {
      var file: File = inputValue.files[0];
      var myReader: FileReader = new FileReader();
  
      myReader.onloadend = (e) => {
        this.imagen = myReader.result;
      }
      myReader.readAsDataURL(file);
    } */




  onSubmit() {
    /*     public _loginCliente(_username: string, _password: string): Promise<any> {
          return this.http.post('https://api.cicili.com.mx:8443/app/logincliente?user=' + _username + '&password=' + _password,
            {
              headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
            })
            .toPromise()
            .then(rslt => rslt)
            .catch(this.handleError); 
        } */



    /*     if (this.userForm.valid) {
          alert('good');
          console.log(this.userForm.value);
          this.HttpWebService.post('app/agregacliente', this.userForm.value).then((response) => {
            console.log('repsonse:' + response);
           
          })
        } else {
          console.log('repsonse:' + this.userForm.value);
          alert('not good')
        } */
  }


  /*   public registro(): void {
      this.alertService.showProgressBar('validando..', 'fa-sign-in');
      this.authService.registro(this.nombre, this.correo_electronico, this.password).then(
        (_rslt: boolean | any) => {
          this.alertService.hideProgressBar();
          if (_rslt) {
            console.log('this.authService.login', _rslt);
            this.router.navigateByUrl('login');
            this.alertService.hideProgressBar();
            this.notificationService.successSmallBox("usuario registrado exitosamente");
            return _rslt;
          } else {
            this.alertService.hideProgressBar();
            this._errorResponse(1);
          }
        },
      );  
    } */
  verificar(form) {
    this.HttpWebService.post('app/verifica/' + this.verifica).then((response) => {
      console.log('response:', response);
      if (response == "true") {
        this.notificationService.successSmallBox("Codigo correcto!. Bienvenido");
        setTimeout(() => {
          this.router.navigateByUrl('/login-user');
          this.modalRef.hide();
        }, 1000);
        return response;
      }
      else {
        this.notificationService.errorBigBox("Codigo invalido.");
        /*  this.notificationService.errorBigBox(response.messageError); */
        console.log("holaaaaaa", response);
      }
      /* if (response.data == "false") {
        this._errorResponse(5);
        console.log(response.data);
        return false;

      }
      else {
        this.notificationService.successBigBox("Codigo SMS correcto!. Bienvenido");
        this.router.navigateByUrl('/login');
        this.modalRef.hide();
        return response;
      } */
    });

  }

  /*   public verificar(): void {
      this.authService.validaSMS(this.verifica).then(
        (_rslt: boolean | any) => {
          this.alertService.hideProgressBar();
          if (_rslt) {
            console.log('this.authService.login', _rslt);
            
            this.notificationService.successSmallBox("Bienvenido Concesionario.", );
           
            return _rslt;
          } else {
            this.alertService.showProgressBar('Error al ingresar.', 'fa-lock', 'Intente nuevamente.');
            setTimeout(() => {
              this.alertService.hideProgressBar();
            }, 3000);
          }
        },
       
      );
    } */




  public login(): void {
  this.fechaNacimiento = this.datePipe.transform(this.fechaNacimiento, "dd/MM/yyyy");
   /*  this.fecha_nacimiento = this.datePipe.transform(this.fecha_nacimiento, "dd/MM/yyyy"); */
/*     this.alertService.showProgressBar('validando..', 'fa-sign-in');
 */    this.authService.registro(this.nombre, this.correo_electronico, this.password, this.apellido_paterno, this.apellido_materno, this.telefono, this.fechaNacimiento).then(
    (_rslt: boolean | any) => {
      /*  this.alertService.showProgressBar('validando', 'fa-sign-in'); */
      console.log('this.authService.login', _rslt);

      if (_rslt === true) {
        this.notificationService.successSmallBox("Registro de Cliente exitosamente");
        setTimeout(() => {
          /* this.router.navigate(['/login']); */
          this.openModal(this.modalTemplate)
        }, 1000);
        return _rslt;
      }
      else {
        this.notificationService.errorBigBox(_rslt.messageError);
        console.log("holaaaaaa", _rslt.messageError);
      }
    },

  );
  }

  /*   function validatePassword(): ValidatorFn {
      return (control: AbstractControl) => {
           let isValid = false;
           if (control && control instanceof FormGroup) {
              let group = control as FormGroup;
              if (group.controls['passwordA'] && group.controls['passwordB']) {
                isValid = group.controls['passwordA'].value == group.controls['passwordB'].value;
              }
            }
           if (isValid) {
              return null;
           } else {
              return { 'passwordCheck': 'failed'}
           }
      }
   } */

  private _errorResponse(typeError: number): void {
    let errorMessage: string = null;
    this.disabled = false;
    ///NO EXISTE EL USUARIO
    if (typeError == 1) {
      //this.isModalErrorConfirmationShow = true;
      errorMessage = 'El cliente ya existe';
    }
    //USUARIO INACTIVO
    else if (typeError == 2) {
      errorMessage = 'Usuario registrado exitosamente. ';
    }
    //SESION ACTIVA POR OTRA PERSONA
    else if (typeError == 3) {
      errorMessage = 'error_login_sesion_activa';
    }
    //VIGENCIA 
    else if (typeError == 4) {
      errorMessage = 'error_login_vigencia';
    }
    else {
      errorMessage = 'Llenas todos los campos';
    }

    this.notificationService.smartMessageBox(
      {
        title: `<i class="fa fa-lock text-danger"></i>&nbsp;<span class='text-danger'><strong>` + 'error' + `</strong></span>`,
        content: errorMessage,
        buttons: '[Ok]'
      },
      buttonPressed => {
        if (buttonPressed == "Ok") {
        }
      }
    );

  }
}
