import { Injectable } from '@angular/core';
import { Subject, Subscription, Observable } from "rxjs/Rx";

@Injectable()
export class AlertService {

  public progressBar: Subject<any>;

  constructor() {
    console.log('AlertService');
    this.progressBar = new Subject<any>();
  }


  public getProgressBarObservable(): Observable<any> {
    return this.progressBar.asObservable();
  }


  public showProgressBar(_title: string = "guardando_cambios", _iconTitle: string = "fa-floppy-o", _body: string = "procesando_informacion"): void {
    console.log('AlertService => showProgressBar');
    this.progressBar.next(
      {
        show: true,
        data: {
          iconTitle: _iconTitle,
          title: _title,
          body: _body
        }
      }
    );
  }

  public hideProgressBar(): void {
    this.progressBar.next(
      {
        show: false
      }
    );
  }

}
