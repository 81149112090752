import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { FormGroup, FormBuilder, Validators, NgForm, AbstractControl, FormControl } from '@angular/forms';
import { HttpWebService } from '../core/http-web.service';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
})
export class ContactoComponent implements OnInit {
  isCollapsed = false;
  contactForm: FormGroup;
  facturaForm: FormGroup;
  countryCodeOptions:any;
  constructor(
    private _scrollToService: ScrollToService,
    private modalService: ModalService,
     private httpWebService: HttpWebService,
     private router: Router,
     private fb: FormBuilder) { }

 



ngOnInit() {
  this.countryCodeOptions = [
    { code: '+52', name: 'MX', flagUrl: 'assets/images/banderas/bandera_mx.png' },
    { code: '+1', name: 'EEUU', flagUrl: 'assets/images/banderas/bandera_eeuu.png' }
    // Agrega más opciones de códigos de país según tus necesidades
    ];  
  
  this.createContactForm();
  (function ($) {
    $(document).ready(function(){
      
      var table = $("#distribuidores").DataTable( {
            dom: '<"top"lf>rt<"bottom"ip><"clear">',
            deferRender:    true,
            scrollY:        '200',
            scrollCollapse: true,
            "pagingType": "simple_numbers",
            "lengthMenu": [ 5, 10, 25 ],
            autoWidth: true,
            columnDefs: [
                {
                    targets: ['_all'],
                    className: 'mdc-data-table__cell'
                }
            ],
            language: {
              emptyTable: "Sin datos disponibles de distribuidores.",
              info: "Mostrando _START_ a _END_ , de _TOTAL_ distribuidores.",
              infoEmpty: "Mostrando 0 registros",
              infoFiltered: "(filtrados de un total de _MAX_ distribuidores.)",
              infoPostFix: "",
              thousands: ",",
              lengthMenu: "Mostrar _MENU_ registros",
              loadingRecords: "Cargando...",
              processing: "Procesando...",
              search: "Buscar distribuidores:",
              zeroRecords: "No matching records found",
            }            
        });
            
        $('#estado').on( 'change', function ($scope) {
          var v_estado = $(this).val();
          if (v_estado == "Todos"){
            v_estado="";
          }
          table.search(v_estado.toString()).draw();
      } );

    });

    
  })(jQuery);
    
}



createContactForm = () => {
  this.contactForm = this.fb.group({
    name:['',[Validators.required]],
    email: ['',[Validators.required, Validators.email]],
    state: ['',[Validators.required]],
    company: ['',[Validators.required]],
    countryCode: ['',[Validators.required]],
    phoneNumber: ['',[Validators.required,]], //Validators.minLength(10), Validators.maxLength(10)
    message: ['',[Validators.required]]
  });
}


public registerContact(): void {

  if(this.contactForm.invalid){
    return this.showValidations(this.contactForm);
  }else{
  console.log(this.contactForm);
  this.httpWebService.post('crucero/registrar_contacto/', this.formatearParametros()).then(
    (_rslt:any) => {
       console.log(_rslt);
      if (_rslt.estatus =="ok" && _rslt.data)  {
        this.modalService.setModalInfo("Mensaje Registrado",
          "Su duda o comentario ha sido registrado, en la brevedad alguien se pondrá en contacto con usted.",true,
          "","","Aceptar","/",true);

        this.modalService.showModal();
        setTimeout(() => {
          this.modalService.hideModal();
          this.router.navigate(['/']);         
        }, 2000);
      } 
      else {
        this.modalService.setModalInfo("Error al enviar la duda o comentario",
        _rslt.mensaje,false,
          "","","Aceptar","",true);
        this.modalService.showModal();
      }
    },
    error => {
      this.modalService.setModalInfo("Error al enviar la duda o comentario",
      "Ocurrió un error al registrar la información",false,
          "","","Aceptar","",true);
      
          this.modalService.showModal();
      console.log("Error al registar la duda/comentario", error);
     }
  );
}



}
formatearParametros=()=>{
  const parametros = {
    "nombre": this.contactForm.get("name").value,
    "email": this.contactForm.get("email").value,
    "ciudad_estado": this.contactForm.get("state").value,
    "empresa": this.contactForm.get("company").value,
    "telefono": this.contactForm.get("phoneNumber").value,
    "duda_comentario": this.contactForm.get("message").value
  }
  return parametros;
  
}
  public triggerScrollTo() {
    
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
 
    this._scrollToService.scrollTo(config);
  }

  showValidations = (form:FormGroup) =>{
    if(form.invalid){
      return Object.values( form.controls ).forEach( control => {
        control.markAsTouched();
      })
    }
  }

//funcion de validos
invalidUserField = (field:string) =>{
  return (this.contactForm.get(field).invalid && this.contactForm.get(field).touched);
}

}