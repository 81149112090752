import { Injectable } from '@angular/core';


declare var $: any;

@Injectable()
export class NotificationService {

  constructor(
   
  ) {
  }

  smallBox(data, cb?) {
    $.smallBox(data, cb)
  }

  bigBox(data, cb?) {
    $.bigBox(data, cb)
  }

  smartMessageBox(data, cb?) {
    $.SmartMessageBox(data, cb)
  }

  private _smallBox(_extraData?: any, _title: string = 'Éxito', _textContent: string = 'exito_mensaje', _iconContent: string = 'fa-smile-o', _cb?): void {
    /**
    * EGMC
    * Declaración de datos default
    */
    let data: any = {
      title: _title,
      content: '<i class="fa ' + _iconContent + '">&nbsp;</i>' + "<i>" + _textContent + "</i>",
      color: "#546483 ",
      iconSmall: "fa fa-fw fa-thumbs-up white bounce animated",
      timeout: 3000
    }

    /**
    * EGMC
    * Se hace la combinación de datos default con los de entrada
    */
    if (_extraData) {
      Object.assign(data, _extraData);
    }

    //console.log(data);

    /**
    * EGMC
    * Se ejecuta la notificación
    */
    this.smallBox(data, _cb);
  }

  /**
   * 
   * @param _extraData 
   * @param _title 
   * @param _textContent 
   * @param _iconContent 
   * @param _cb 
   */
  private _bigBox(_extraData?: any, _title: string = 'Éxito', _textContent: string = 'exito_mensaje', _cb?): void {
    /**
    * EGMC
    * Declaración de datos default
    */
    let data: any = {
      title: _title,
      content: '<i class="" style="z-index: 100000">&nbsp;</i>' + "<i> " + _textContent + "</i>", 
      color: "#546483 ",
      icon: "fa fa-thumbs-up white bounce animated",
      timeout: 7000,
      number: null
    }

    /**
    * EGMC
    * Se hace la combinación de datos default con los de entrada
    */
    if (_extraData) {
      Object.assign(data, _extraData);
    }

    //console.log(data);

    /**
    * EGMC
    * Se ejecuta la notificación
    */
    this.bigBox(data, _cb);
  }

  /**
   * Muestra notificación de exito
   * @param _data 
   * @param _cb 
   */
  public successSmallBox(_textContent: string = 'exito_mensaje', _title: string = 'Éxito', _extraData?: any, _iconContent: string = 'fa-smile-o', _cb?): void {
    this._smallBox(_extraData, _title, _textContent, _iconContent, _cb);
  }


  /**
   * Muestra notificación de error
   * @param _data 
   * @param _cb 
   */
  public errorSmallBox(_textContent: string = 'error_mensaje', _title: string = 'error', _extraData?: any, _iconContent: string = 'fa-frown-o', _cb?): void {
    let data = {
      color: "#546483",
      iconSmall: "fa fa-fw fa-close white bounce animated",
      timeout: 7000
    };
    /**
    * EGMC
    * Se hace la combinación de datos default con los de entrada
    */
    if (_extraData) {
      Object.assign(data, _extraData);
    }
    this._smallBox(data, _title, _textContent, _iconContent, _cb);
  }

  /**
   * Muestra notificación de error
   * @param _data 
   * @param _cb 
   */
  public withoutPermitsSmallBox(_extraData?: any, _title: string = 'sin_permisos', _textContent: string = 'sin_permisos_mensaje', _iconContent: string = 'fa-frown-o', _cb?): void {
    let data = {
      color: "#546483",
      iconSmall: "fa fa-fw fa-lock white bounce animated",
      timeout: 5000
    };
    /**
    * EGMC
    * Se hace la combinación de datos default con los de entrada
    */
    if (_extraData) {
      Object.assign(data, _extraData);
    }
    this._smallBox(data, _title, _textContent, _iconContent, _cb);
  }


  
  public successBigBox(_textContent: string = 'exito_mensaje', _title: string = 'Éxito', _extraData?: any, _cb?): void {
    this._bigBox(_extraData, _title, _textContent, _cb);
  }
  
  /**
   * Muestra notificación de error
   * @param _data 
   * @param _cb 
   */
  public errorBigBox(_textContent: string = 'error_mensaje', _title: string = 'error', _extraData?: any, _cb?): void {
    let data = {
      color: "#546483",
      icon: "fa fa-warning swing animated",
      timeout: 7000,
      number: null
    };
    /**
    * EGMC
    * Se hace la combinación de datos default con los de entrada
    */
    if (_extraData) {
      Object.assign(data, _extraData);
    }
    this._bigBox(data, _title, _textContent, _cb);
  }

  /**
   * Muestra notificación de advertencia
   * @param _data 
   * @param _cb 
   */
  public warningBigBox(_textContent: string = 'advertencia_mensaje', _title: string = 'advertencia', _extraData?: any, _cb?): void {
    let data = {
      color: "#f1c40f",
      icon: "fa fa-warning swing animated",
      timeout: 7000,
      number: null
    };
    /**
    * EGMC
    * Se hace la combinación de datos default con los de entrada
    */
    if (_extraData) {
      Object.assign(data, _extraData);
    }
    this._bigBox(data, _title, _textContent, _cb);
  }

}
