import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { HttpWebService } from '../../app/core/http-web.service'

import * as moment from 'moment';

import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthServiceReg {
  public muestraerror: boolean;
  public nombre: string;
  public correo_electronico: string;
  public password: string;
  public loading: any;
  public data: any;
  public recuerdame: boolean;
  private _session: any = {
    isLoggedIn: false,
    timeSession: '',
  };
  private _sessionServer: any = {
    tokenAuth: '',
    timeSession: '',
  };

  constructor(
    public http: HttpClient,
    private router: Router
  ) {


  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }


  public _registro(_nombre: string, _correo_electronico: string, _password: string, _apellido_paterno: string, _apellido_materno: string, _telefono: string, _fechaNacimiento: string): Promise<any> {
    //return this.HttpWebService.post('app/user?user='+_nombre+ '&correo_electronico='+_correo_electronico);
    return this.http.post('https://api.cicili.com.mx:8443/app/agregacliente?nombre=' + _nombre + '&correo_electronico=' + _correo_electronico + '&password=' + _password + '&apellido_paterno=' + _apellido_paterno + '&apellido_materno=' + _apellido_materno + '&telefono=' + _telefono + '&fechaNacimiento=' + _fechaNacimiento
    ,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      })
      .toPromise() 
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public registro(_nombre: string, _correo_electronico: string, _password: string, _apellido_paterno: string, _apellido_materno: string, _telefono: string, _fechaNacimiento: string): Promise<boolean | any> {
    return this._registro(_nombre, _correo_electronico, _password, _apellido_paterno, _apellido_materno, _telefono, _fechaNacimiento).then(
      rslt => {
        console.log('AuthService => registro', rslt);
        if (rslt.data) {
          this.setDataLocalStorageSession(rslt.data);
          return true;
        }
        return rslt;
      },
      error => {
        console.log('AuthService => registro => error', error);
        return false;
      }
    );
  }


  public _concesionario(_nombre: string, _correo_electronico: string, _password: string, _apellido_paterno: string, _apellido_materno: string, _telefono: string): Promise<any> {
    //return this.HttpWebService.post('app/user?user='+_nombre+ '&correo_electronico='+_correo_electronico);
    return this.http.post('https://api.cicili.com.mx:8443/app/agregaconcesionario?nombre=' + _nombre + '&correo_electronico=' + _correo_electronico + '&password=' + _password + '&apellido_paterno=' + _apellido_paterno + '&apellido_materno=' + _apellido_materno + '&telefono=' + _telefono 
    ,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      })
      .toPromise() 
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public concesionario(_nombre: string, _correo_electronico: string, _password: string, _apellido_paterno: string, _apellido_materno: string, _telefono: string): Promise<boolean | any> {
    return this._concesionario(_nombre, _correo_electronico, _password, _apellido_paterno, _apellido_materno, _telefono).then(
      rslt => {
        console.log('AuthService => registro', rslt);
        if (rslt.data) {
          this.setDataLocalStorageSession(rslt.data);
          return true;
        }
        return rslt;
      },
      error => {
        console.log('AuthService => registro => error', error);
        return false;
      }
    );
  }

  public _validaSMS(_verifica: string): Promise<any> {
    //return this.HttpWebService.post('app/user?user='+_username+ '&password='+_password);
    return this.http.post('https://api.cicili.com.mx:8443/app/verifica/' + _verifica,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      })
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public validaSMS(_verifica: string): Promise<boolean | any> {
    return this._validaSMS(_verifica).then(
      rslt => {
        console.log('AuthService => validaSMS', rslt);
        if (rslt.data) {
          this.setDataLocalStorageSession(rslt.data);
          return true;
        }
        return rslt;
      },
      error => {
        console.log('AuthService => validaSMS => error', error);
        return false;
      }
    );
  }

  public setDataLocalStorageSession(_data: any): void {
    this._session.isLoggedIn = true;
    this._session.timeSession = moment().format();

    this._sessionServer = {
      "tokenAuth": _data.token,
      "timeSession": moment().format('YYYY-MM-DD HH:mm:ss')
    };
    console.log(this._sessionServer);
    localStorage.setItem('session', JSON.stringify(this._session));

    localStorage.setItem('sessionServer', CryptoJS.AES.encrypt(JSON.stringify(this._sessionServer), "AGS"));
  }

  public getTokenAuth(): string {
    let _sessionServer: any = localStorage.getItem('sessionServer');
    if (_sessionServer) {
      _sessionServer = JSON.parse(CryptoJS.AES.decrypt(_sessionServer, "AGS").toString(CryptoJS.enc.Utf8));
    }
    return _sessionServer != null ? _sessionServer.tokenAuth : null;
  }

}