import { Component, OnInit, TemplateRef,ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { HttpWebService } from '../core/http-web.service';
import { ActivatedRoute } from '@angular/router';
import { isScheduler } from 'rxjs/internal-compatibility';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.css']
})
export class TiendaComponent implements OnInit {
  isCollapsed = false;
  constructor(private _scrollToService: ScrollToService, 
              private modalService: BsModalService, 
              private route: Router,
              private rutaActiva: ActivatedRoute,
              private el: ElementRef,
              private HttpWebService: HttpWebService) { }

  pageActual: number = 1;
  pageSize: number = 9;
  customPageSize: number = 9;
  pageTotal: number = 15;
  pageCurrent: number = 9;
  btnShow: string = "Mostrar Todo";
  productos: any = [];
  maxSelectedProducts: number = 3;
  isShowingAll: boolean = false;
  selectedIDs: number[] = [];
  parametrosSeleccionados: string[] = [];
  loading:boolean = true;
  palabraBuscar: string = "";

  ngOnInit() {
    //this.parametrosSeleccionados.push("tienda");
    //this.loadProducts(this.parametrosSeleccionados);
    this.scrollToTop();
    this.rutaActiva.params.subscribe( parametros =>{
      this.validateParams(parametros["palabra"]); 
    
    })
  }

  scrollToTop() {
    
    const element = this.el.nativeElement;
    //window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  validateParams = (param:string) =>{
    if(param===""){
      console.log("No se seleccionaron zapatos para comparar");
    }else{
      this.palabraBuscar = param;
      console.log("palabra: " , this.palabraBuscar);
      this.consultaTipoZapato(this.palabraBuscar);
    }
  }

  consultaTipoZapato = (parametros:string)=>{
    /* conexion */
    if (parametros=="all"){
      this.parametrosSeleccionados.push("tienda");
      this.loadProducts(this.parametrosSeleccionados);
      
    }else{
      this.HttpWebService.getNoHeaderAuthorization('crucero/consulta_tipo/?palabra=' + parametros).then((response) => {
        //console.log(response);
        if (response.estatus=="yes"){
          this.productos = response.data.zapatos;
        }
        else{
          let idUser = this.getIdUser();
          this.HttpWebService.getNoHeaderAuthorization('crucero/buscador_zapatos/?tienda=1&code=' + idUser + "&" + response.data).then((response_r) => {
            //console.log(response);
            this.productos = response_r.data.zapatos;
      
            console.log("++++++productos+++++++++", this.productos);
            this.loading = false;
          })
        }
        this.loading = false;
      })
    }
  }

  getIdUser = ()=>{
    let idUser: number = 0;
    let _localSession: any = localStorage.getItem('session');
    if (_localSession){
      _localSession = JSON.parse(CryptoJS.AES.decrypt(_localSession, "AGS").toString(CryptoJS.enc.Utf8));
      idUser = _localSession.userId;
    }
    return idUser;
  } 

  loadProducts = (parametros:string[])=>{
    /* conexion */
    const params = this.formatearParametros(parametros);
    
    console.log("Parámetros: " + params);
    
    
    this.HttpWebService.getNoHeaderAuthorization('crucero/buscador_zapatos/' + params).then((response) => {
      //console.log(response);
      this.productos = response.data.zapatos;

      console.log("++++++productos+++++++++", this.productos);
      this.loading = false;
    })
  }

  formatearParametros = (parametros:string[])=>{
    let idUser = this.getIdUser();
    if(parametros.length==0){
      return "";
    }else{
      return "?code=" + idUser + "&" + parametros.join("=1&") + "=1";
    }
  }

  btnClick() {
    if(this.selectedIDs.length>1){
     this.route.navigate(['/comparador',this.selectedIDs.toString()]);
    }   
 };

 showAll = () =>{
  if(this.isShowingAll){
    this.customPageSize = this.pageSize;
  }else{
    this.customPageSize = this.productos.length;
  }
  this.pageActual = 1;
  this.isShowingAll = !this.isShowingAll;
}

  public triggerScrollTo() {
    
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
 
    this._scrollToService.scrollTo(config);
  }

  onChecked = (obj:{event:any, selectedID:number}) =>{
    //console.log(obj.event.target.checked + " " + obj.selectedID);

      if(obj.event.target.checked){
        this.selectedIDs.push(obj.selectedID)
      }
      else{
        const index = this.selectedIDs.indexOf(obj.selectedID);
        if (index > -1) {
          this.selectedIDs.splice(index, 1);
      }
    }

    //console.log("Número de Productos Seleccionados: " + this.selectedIDs.length);
  }

  onSelectedFilter = (obj:{event:any, selectedID:string}) =>{
    console.log(obj.selectedID + " " + obj.event.target.checked);
    if(obj.event.target.checked){
      this.parametrosSeleccionados.push(obj.selectedID);
    }else{
      const index = this.parametrosSeleccionados.indexOf(obj.selectedID);
      console.log("index: " + index)
      if (index > -1) {
        this.parametrosSeleccionados.splice(index, 1);
      }
    }
    console.log(this.parametrosSeleccionados);
    this.loadProducts(this.parametrosSeleccionados);
  }

  onTextFind = (obj:{event:any, texto:string}) =>{
    console.log("texto a buscar " + obj.texto );
  
    this.validateParams(obj.texto ); 
    
   
    
  }

}
