import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { HttpWebService } from '../core/http-web.service';
import { DireccionService } from './direccion.service';

@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  usosFactura: any[] = [];
  
  constructor(private _httpWebService:HttpWebService,
              private _direccionService:DireccionService) { 
  
    this._httpWebService.get('modelos_compartidos/get_usos_cfdi/').then((response) => {
      console.log("Respuesta:");
      console.log(response);
      this.usosFactura = response.data;

      //console.log("++++++productos+++++++++", this.productos);
    })
  }

  save = (facturacionForm:FormGroup,editFacturacion:boolean,error) =>{
    let method = "registrar_datos_facturacion/";

    if(editFacturacion){
      method = "editar_datos_facturacion/"
    }

    this._httpWebService.post('crucero/' + method,this.getParameters(facturacionForm)).then((response) => {
      if(response){
        if(response.estatus=="ok"){
          error(false);
        }else{
          error(true);
        }
      }else{
        error(true);
      }
    })
    
  }

  getParameters = (facturacionForm:FormGroup) =>{
    return {
      "razon_social": facturacionForm.get("razonSocial").value,
      "rfc": facturacionForm.get("rfc").value,
      "calle_numero": facturacionForm.get("direccion").value,
      "colonia": facturacionForm.get("colonia").value,
      "municipio_id": facturacionForm.get("municipio").value,
      "estado_id": facturacionForm.get("estado").value,
      "cp": facturacionForm.get("codigoPostal").value,
      "usocfdi_id": facturacionForm.get("usoFactura").value,
    }
  }
}
