import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { HttpWebService } from '../../app/core/http-web.service'

import * as moment from 'moment';

import * as CryptoJS from 'crypto-js';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class AuthService {
  public muestraerror: boolean;
  public _userName: string;
  public loading: any;
  public data: any;
  public recuerdame: boolean;
  public mensaje:string = '';
  
  private _session: any = {
    isLoggedIn: false,
    userName: '',
    tokenAuth: '',
    refreshToken: '',
    tokenCreationDate: '',
    tokenExpirationDate: '',
    mayorista_distribuidor: false
    //userDistribuidor: false,
    //userMayorista: false
  };

  constructor(
    public http: HttpClient,
    private router: Router,
  ) {


  }

  public getHttpHeaders() {
    console.log("Token " + this.getTokenAuth())
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', "Bearer " + this.getTokenAuth())
      .append('Content-Language', localStorage.getItem('selectedLanguage') || 'es-MX')
  }
  public getHttpHeadersToken() {
    console.log("Token " + this.getTokenAuth())
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', "Token " + this.getTokenAuth())
      .append('Content-Language', localStorage.getItem('selectedLanguage') || 'es-MX')
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  public _login(_username: string, _password: string): Promise<any> {
    const formData = new FormData();
    formData.append('username', _username);
    formData.append('password',_password);

    return this.http.post('https://django.zapatoscrucero.com/usuarios/accounts/auth/',formData)
    //return this.http.post('http://127.0.0.1:9000/usuarios/accounts/auth/',formData)
    //return this.http.post('https://bezapatos.fusion-ti.com/usuarios/accounts/auth/',formData)
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public login(_username: string, _password: string): Promise<boolean> {
    return this._login(_username, _password).then(
      rslt => {
        console.log('AuthService => login', rslt);
        this.mensaje = rslt.message
        if (rslt.estatus=="ok") {
          this.setDataLocalStorageSession(rslt);
          return true;
        }
        return false;
      },
      error => {
        console.log('AuthService => login => error', error);
        return false;
      }
    );
  }

  public _register(_newUser: any): Promise<any> {
    const formData = new FormData();
    formData.append('nombre', _newUser.name);
    formData.append('apellidos',_newUser.lastName);
    formData.append('email', _newUser.email);
    formData.append('nombre_usuario',_newUser.userName);
    formData.append('contrasena', _newUser.password);
    formData.append('telefono',_newUser.phoneNumber);
    formData.append('distribuidor', _newUser.userType=='distribuidor'?'1':'0');
    formData.append('mayorista',_newUser.userType=='mayorista'?'1':'0');
    formData.append('empresa', _newUser.company);
    formData.append('estado',_newUser.state);
    formData.append('ciudad', _newUser.city);

    
    return this.http.post('https://django.zapatoscrucero.com/usuarios/registrar_usuario/',formData)
    //return this.http.post('http://127.0.0.1:9000/usuarios/registrar_usuario/',formData)
    //return this.http.post('https://bezapatos.fusion-ti.com/usuarios/registrar_usuario/',formData)
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }
  public _code_validation(_newCode: any): Promise<any> {
    const formData = new FormData();
    formData.append('code', _newCode.code);
    
    return this.http.post('https://django.zapatoscrucero.com/usuarios/validar_codigo/',formData)
    //return this.http.post('http://127.0.0.1:9000/usuarios/validar_codigo/',formData)
    //return this.http.post('https://bezapatos.fusion-ti.com/usuarios/validar_codigo/',formData)
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public register(_newUser: any): Promise<boolean | any> {
    return this._register(_newUser).then(
      rslt => {
        console.log('AuthService => register => User Registered', rslt);
        if (rslt.estatus=="success") {
          //this.setDataLocalStorageSession(rslt, );
          return true;
        }
        return rslt;
      },
      error => {
        console.log('AuthService => login => error', error);
        return false;
      }
    );
  }

  public code_valitation(_newCode: any): Promise<boolean | any> {
    return this._code_validation(_newCode).then(
      rslt => {
        if (rslt.estatus=="success") {
          return true;
        }
        return rslt;
      },
      error => {
        console.log('code_valitation => login => error', error);
        return false;
      }
    );
  }


  public _forgot(_email: string): Promise<any> {
    const formData = new FormData();
    console.log(_email);
    formData.append('email', _email);
    
    return this.http.post('https://django.zapatoscrucero.com/usuarios/api/password_reset/',formData)
    //return this.http.post('http://127.0.0.1:9000/usuarios/api/password_reset/',formData)
    //return this.http.post('https://bezapatos.fusion-ti.com/usuarios/api/password_reset/',formData)
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public forgot(_email: string): Promise<boolean | any> {
    return this._forgot(_email).then(
      rslt => {
        console.log('AuthService => login', rslt);
        return rslt;
      },
      error => {
        console.log('AuthService => login => error', error);
        return false;
      }
    );
  }

  public _changePassword(_password: string, _password2: string): Promise<any> {
    const formData = new FormData();
    //formData.append('new_password', _password);
    //formData.append('confirm_password', _password);
    
    return this.http.post('https://django.zapatoscrucero.com/usuarios/cambiar_password/',{"new_password":_password,"confirm_password":_password2},{ headers: this.getHttpHeadersToken() })
    //return this.http.post('http://127.0.0.1:9000/usuarios/cambiar_password/',{"new_password":_password,"confirm_password":_password2},{ headers: this.getHttpHeadersToken() })
    //return this.http.post('https://bezapatos.fusion-ti.com/usuarios/cambiar_password/',{"new_password":_password,"confirm_password":_password2},{ headers: this.getHttpHeadersToken() })
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public changePassword(_password: string, _password2: string): Promise<boolean | any> {
    return this._changePassword(_password,_password2).then(
      rslt => {
        console.log('AuthService => changePassword', rslt);
        if (rslt.data) {
          return true;
        }
        return false;
      },
      error => {
        console.log('AuthService => changePassword => error', error);
        return false;
      }
    );
  }
  

  public _restablece(_id: string, _password: string): Promise<any> {
    return this.http.post('https://api.cicili.com.mx:8443/app/restablecepassword?id=' + _id + '&password=' + _password,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      })
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public restablece(_id: string, _password: string): Promise<boolean | any> {
    return this._restablece(_id, _password).then(
      rslt => {
        console.log('AuthService => login', rslt);
        if (rslt.data) {
          return true;
        }
        return rslt;
      },
      error => {
        console.log('AuthService => login => error', error);
        return false;
      }
    );
  }
  public _loginCliente(_username: string, _password: string): Promise<any> {
    return this.http.post('https://api.cicili.com.mx:8443/app/logincliente?user=' + _username + '&password=' + _password,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      })
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public loginCliente(_username: string, _password: string): Promise<boolean | any> {
    return this._loginCliente(_username, _password).then(
      rslt => {
        console.log('AuthService => login', rslt);
        if (rslt.data) {
          this.setDataLocalStorageSession(rslt.data);
          return true;
        }
        return rslt;
      },
      error => {
        console.log('AuthService => login => error', error);
        return false;
      }
    );
  }




  public _loginAdministrador(_username: string, _password: string): Promise<any> {
    return this.http.post('https://api.cicili.com.mx:8443/app/loginadministrador?user=' + _username + '&password=' + _password,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
      })
      .toPromise()
      .then(rslt => rslt)
      .catch(this.handleError);
  }

  public loginAdministrador(_username: string, _password: string): Promise<boolean | any> {
    return this._loginAdministrador(_username, _password).then(
      rslt => {
        console.log('AuthService => login', rslt);
        if (rslt.data) {
          this.setDataLocalStorageSession(rslt.data);
          return true;
        }
        return rslt;
      },
      error => {
        console.log('AuthService => login => error', error);
        return false;
      }
    );
  }


  public setDataLocalStorageSession(_data: any): void {
    this._session.isLoggedIn = true;
    this._session.timeSession = moment().format();
    this._userName = _data.usuario.nombre + " " + _data.usuario.apellido;

    this._session = {
      "isLoggedIn": true,
      "userName": _data.usuario.nombre + " " + _data.usuario.apellido,
      "userId": _data.usuario.id,
      "tokenAuth": _data.access_token,
      "refreshToken": _data.refresh_token,
      "tokenCreationDate": moment().format(),
      "tokenExpirationDate": moment().add('second', _data.expires_in).format(),
      "mayorista_distribuidor": _data.usuario.mayorista_distribuidor,
      "tipo_usuario": _data.usuario.tipo_usuario
      //"userDistribuidor": _data.usuario.distribuidor,
      //"userMayorista": _data.usuario.mayorista
    }
    
    
    console.log('mayorista_distribuidor: ' + this._session.mayorista_distribuidor);
    localStorage.setItem('session', CryptoJS.AES.encrypt(JSON.stringify(this._session), "AGS"));
    console.log('mayorista_distribuidor localStorage: ' + this.getSession().mayorista_distribuidor);
    
  }

  public closeSession(){
    localStorage.removeItem('session');
  }
  public isSessionValid(): boolean {
    let _localSession: any = localStorage.getItem('session');
    console.log("_localSession : " + _localSession);
    this._userName = '';
    if (_localSession) {
      _localSession = JSON.parse(CryptoJS.AES.decrypt(_localSession, "AGS").toString(CryptoJS.enc.Utf8));
      let today = moment();
      let expirationDate = _localSession.tokenExpirationDate;

      if(today.isBefore(_localSession.tokenExpirationDate)){
        this._userName = _localSession.userName;
        return true;
      }else{
        return false;
      }
      
    }
    return false;
  }

  public getTokenAuth(): string {
    let _session: any = localStorage.getItem('session');
    if (_session) {
      _session = JSON.parse(CryptoJS.AES.decrypt(_session, "AGS").toString(CryptoJS.enc.Utf8));
    }
    return _session != null ? _session.tokenAuth : null;
  }

  getUserName = () =>{
    if(this.isSessionValid()){
      return this._userName;
      console.log("Token " + this.getTokenAuth());
    }else{
      return '';
    }
  }

  getSession = () =>{
    let _session: any = null
    if(this.isSessionValid()){
      _session = localStorage.getItem('session');
      console.log("_session " +_session);
      if (_session) {
        _session = JSON.parse(CryptoJS.AES.decrypt(_session, "AGS").toString(CryptoJS.enc.Utf8));
      }
    }
    return _session != null ? _session : null;
  }
  isAuthenticaded =():boolean =>{
    return this.isSessionValid();
  }
}