import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWebService } from '../../app/core/http-web.service'
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../app/login/auth.service';
import { AlertService } from "../../app/shared/layout/alerts/alert.service";
import { NotificationService } from '../../app/shared/utils/notification.service';
import { FormGroup, FormBuilder, Validators, NgForm, AbstractControl, FormControl } from '@angular/forms';
import { ModalService } from '../services/modal.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public errorMessage: string = '';
  //public user: string;
 // public password: string;
  public loading: any;
  public data: any;
  //radioModel = 'Cliente';
  public disabled: boolean = false;
  public activeTab:string = "login";
  public isUserTypeChecked:boolean = false;
  public isEnableRegisterButton:boolean = false;
  countryCodeOptions:any;

  loginForm: FormGroup;
  userForm: FormGroup;
  passwordForm: FormGroup;
  codeForm: FormGroup;

  constructor(
    private HttpWebService: HttpWebService,
    private authService: AuthService,
    public http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private modalService: ModalService
  ) {
    this.createLoginForm();
    this.createUserForm();
    this.createPasswordForm();
    this.createCodeForm();
  }

  ngOnInit() {
    this.countryCodeOptions = [
      { code: '+52', name: 'MX', flagUrl: 'assets/images/banderas/bandera_mx.png' },
      { code: '+1', name: 'EEUU', flagUrl: 'assets/images/banderas/bandera_eeuu.png' }
      // Agrega más opciones de códigos de país según tus necesidades
      ];  
    
  }

  createLoginForm = () => {
    this.loginForm = this.fb.group({
      userName: ['',[Validators.required]],
      password: ['',[Validators.required]]
      
    });
  }

  createPasswordForm = () => {
    this.passwordForm = this.fb.group({
      email: ['',[Validators.required,Validators.email]]
      
    });
  }
  createCodeForm = () => {
    this.codeForm = this.fb.group({
      code: ['',[Validators.required,Validators.minLength(6), Validators.maxLength(6)]]
      
    });
  }

  createUserForm = () => {
    this.userForm = this.fb.group({
      name:['',[Validators.required]],
      lastName: ['',[Validators.required]],
      userName: ['',[Validators.required]],
      password: ['',[Validators.required, Validators.minLength(8)]],
      email: ['',[Validators.required, Validators.email]],
      countryCode: ['',[Validators.required]],
      phoneNumber: ['',[Validators.required]],   //Validators.minLength(10), Validators.maxLength(10)
      userType: [''],
      company: new FormControl({value:'',disabled:true}),
      city: new FormControl({value:'',disabled:true}),
      state: new FormControl({value:'',disabled:true}),
      terms: ['',[Validators.required]]
    });

    

    this.userForm.get('userType').valueChanges
    .subscribe(value => {
      const companyControl = this.userForm.get('company');
      const cityControl = this.userForm.get('city');
      const stateControl = this.userForm.get('state');

      companyControl.clearValidators();
      cityControl.clearValidators();
      stateControl.clearValidators();
      companyControl.updateValueAndValidity({emitEvent:false, onlySelf:true});
      cityControl.updateValueAndValidity({emitEvent:false, onlySelf:true});
      stateControl.updateValueAndValidity({emitEvent:false, onlySelf:true});
      companyControl.disable();
      cityControl.disable();
      stateControl.disable();

      if(value) {
        companyControl.setValidators(Validators.required);
        companyControl.updateValueAndValidity({emitEvent:false, onlySelf:true});
        cityControl.setValidators(Validators.required);
        cityControl.updateValueAndValidity({emitEvent:false, onlySelf:true});
        stateControl.setValidators(Validators.required);
        stateControl.updateValueAndValidity({emitEvent:false, onlySelf:true});
        companyControl.enable();
        cityControl.enable();
        stateControl.enable();
      }
    });
  }

  invalidLoginField = (field:string) =>{
    return (this.loginForm.get(field).invalid && this.loginForm.get(field).touched);
  }

  invalidPasswordField = (field:string) =>{
    return (this.passwordForm.get(field).invalid && this.passwordForm.get(field).touched);
  }

  invalidUserField = (field:string) =>{
    return (this.userForm.get(field).invalid && this.userForm.get(field).touched);
  }
  invalidCodeField = (field:string) =>{
    return (this.codeForm.get(field).invalid && this.codeForm.get(field).touched);
  }

  changeForm = (tabName:string) =>{
    this.activeTab = tabName;
  }
  checkUserType = () =>{
    this.isUserTypeChecked = true;
  }

  enableRegisterButton = () => {
    this.isEnableRegisterButton = true;
  }

  showValidations = (form:FormGroup) =>{
    if(form.invalid){
      return Object.values( form.controls ).forEach( control => {
        control.markAsTouched();
      })
    }
  }
  resetMessage = () =>{
    console.log("borrando mensaje");
    this.errorMessage = '';
  }
  public login(): void {
    if(this.loginForm.invalid){
      return this.showValidations(this.loginForm);
    }else{

    this.errorMessage = '';
    this.authService.login(this.loginForm.controls.userName.value, this.loginForm.controls.password.value).then(
      (_rslt: boolean | any) => {
         console.log(_rslt);
        if (_rslt)  {
          this.modalService.setModalInfo("Información",
          "Bienvenido " +  this.authService.getUserName() + "!!!",true,
          "","","Aceptar","/tienda/all",false); // tienda

          this.modalService.showModal();
          /*setTimeout(() => {
            this.modalService.hideModal();
            this.router.navigate(['/tienda']);
           
          }, 10000);*/
          return _rslt;
        } 
        else {
          this.errorMessage = this.authService.mensaje;
          //this.notificationService.errorBigBox(_rslt.messageError);
         /*  this.notificationService.errorBigBox(_rslt.messageError); */
          console.log("Error al firmarse", _rslt);
        }
        //  if (_rslt) {
        //   console.log('this.authService.login', _rslt);
        //   localStorage.setItem('session', this.data);
        //   this.notificationService.successSmallBox("Bienvenido Concesionario.", );
        //   this.router.navigate(['/tienda']);
        //   return _rslt;
        // } else {
        //   this.alertService.showProgressBar('Error al ingresar.', 'fa-lock', 'Intente nuevamente.');
        //   setTimeout(() => {
        //     this.alertService.hideProgressBar();
        //   }, 3000);
        // } 
      },
      error => {
        this.errorMessage = 'Credenciales incorrectas';
         //this.error = error;
         //this.alertService.hideProgressBar();
         //window.alert("credenciales incorrectas")
         console.log("Error al firmarse: ", error);
       }
    );
      }
  }

  public register(): void {
    if(!this.userForm.get("terms").value){
      this.userForm.get("terms").markAsTouched();
      return;
    }
    if(this.userForm.invalid){
      return this.showValidations(this.userForm);
    }else{

    this.authService.register(this.userForm.value).then(
      (_rslt: boolean | any) => {
         console.log(_rslt);
        if (_rslt==true)  {
          this.modalService.setModalInfo("Registro de cuenta",
          "Se envió un código de acceso al correo electrónico que registraste, por favor capturalo cuando se te pida.",true,
          "","","Aceptar","",false);  //"","","Aceptar","/",false)

          this.modalService.showModal();
          setTimeout(() => { 
            this.modalService.hideModal();
          }, 2000);
          this.activeTab = 'code';
          /*setTimeout(() => {
            this.modalService.hideModal();
            this.router.navigate(['/']);         
          }, 2000);*/
        } 
        else {
          this.modalService.setModalInfo("Error al procesar el Registro",
          _rslt.message,false,
          "","","Aceptar","",true);
          
          this.modalService.showModal();
        }
      },
      error => {
        this.modalService.setModalInfo("Error al procesar el Registro",
        "Ocurrió un error al registrar la información",false,
          "","","Aceptar","",true);
        this.modalService.showModal();
        console.log("Error al firmarse", error);
       }
    );
      }
  }

  public valida_codigo(): void {
    if(this.codeForm.invalid){
      return this.showValidations(this.codeForm);
    }else{

    this.authService.code_valitation(this.codeForm.value).then(
      (_rslt: boolean | any) => {
         console.log(_rslt);
        if (_rslt==true)  {
          this.modalService.setModalInfo("Registro de cuenta",
          "El código se valido, ahora puedes entrar con tu usuario y contraseña",true,
          "","","Aceptar","#/cart",false);  //"","","Aceptar","/",false)

          this.modalService.showModal();
          this.activeTab = 'login';
        } 
        else {
          this.modalService.setModalInfo("Error al procesar la validación del código",
          _rslt.message,false,
          "","","Aceptar","",true);
          
          this.modalService.showModal();
        }
      },
      error => {
        this.modalService.setModalInfo("Error al procesar la validación",
        "Ocurrió un error al validar el código",false,
          "","","Aceptar","",true);
        this.modalService.showModal();
        console.log("Error al firmarse", error);
       }
    );
      }
  }

  recuperar = () =>{
    if(this.passwordForm.invalid){
      return this.showValidations(this.passwordForm);
    }else{
      console.log("Correo:" + this.passwordForm.get("email").value)
      this.authService.forgot(this.passwordForm.get("email").value).then(
        (_rslt: boolean | any) => {
           console.log(_rslt);
          if (_rslt==false)  {
            this.modalService.setModalInfo("Error",
            "Ocurrió un error al reestablecer la contraseña",false,
              "","","Aceptar","",true);
            this.modalService.showModal();
            console.log("Error al resetear el password");
          }else {
            if(_rslt.email){
              this.modalService.setModalInfo("Información",
              _rslt.email,true,
              "","","Aceptar","",true);
              this.modalService.showModal();
            }else{
              this.modalService.setModalInfo("Error",
              "No se encontró una cuenta con la información proporcionada",false,
                "","","Aceptar","",true);
              this.modalService.showModal();
              console.log("Error al resetear el password");
            }
            
          }
        }
      );
    }
  }
  /*private _errorResponse(typeError: number): void {
    let errorMessage: string = null;
    this.disabled = false;
    ///NO EXISTE EL USUARIO
    if (typeError == 1) {
      //this.isModalErrorConfirmationShow = true;
      errorMessage = 'error_login_no_existe_usuario';
    }
    //USUARIO INACTIVO
    else if (typeError == 2) {
      errorMessage = 'error_login_inactivo';
    }
    //SESION ACTIVA POR OTRA PERSONA
    else if (typeError == 3) {
      errorMessage = 'error_login_sesion_activa';
    }
    //VIGENCIA 
    else if (typeError == 4) {
      errorMessage = 'error_login_vigencia';
    }
    else {
      errorMessage = 'Password y/o usuario incorrectos. Intenta nuevamente';
    }

    this.notificationService.smartMessageBox(
      {
        title: `<i class="fa fa-lock text-danger"></i>&nbsp;<span class='text-danger'><strong>` + 'error' + `</strong></span>`,
        content: errorMessage,
        buttons: '[Ok]'
      },
      buttonPressed => {
        if (buttonPressed == "Ok") {
        }
      }
    );

  }*/

}
