import { Injectable } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  passwordForm:FormGroup;

  constructor(private fb:FormBuilder) { 
    this.createPasswordForm();
  }

  createPasswordForm = () =>{
    this.passwordForm = this.fb.group({
      password: ['',[Validators.required,Validators.minLength(8)]],
      confirmPassword: ['',[Validators.required,Validators.minLength(8)]]
      
    });
  }

  showValidations = (form:FormGroup) =>{
    if(form.invalid){
      return Object.values( form.controls ).forEach( control => {
        control.markAsTouched();
      })
    }
  }

  invalidPasswordField = (field:string) =>{
    return (this.passwordForm.get(field).invalid && this.passwordForm.get(field).touched);
  }

  passwordMismatch = () => {
    return this.passwordForm.get("password").value != this.passwordForm.get("confirmPassword").value;
  }
}
