import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'app-alert-confirm-delete',
    template: `
    <alert type="danger" class="no-margin fade in" dismissible="true">
        <h5 class="alert-heading">
            <i class="danger fa fa-fw {{iconTitle}}"></i>
           
        </h5>
       
        <p class="text-align-right">
            <a (click)="userResponse(false)" class="btn btn-sm btn-default">
                <strong></strong>
            </a>
            <a (click)="userResponse(true)" class="btn btn-sm btn-danger">
                <strong></strong>
            </a>
        </p>
    </alert>
  `,
    styles: []
})
export class ConfirmDeleteComponent implements OnInit {

    @Input() title: string = 'eliminar_registro';
    @Input() body: string = 'eliminar_registro_pregunta';
    @Input('text-button-yes') textButtonYes: string = 'eliminar_registro_si';
    @Input('text-button-no') textButtonNo: string = 'eliminar_registro_no';
    @Input() iconTitle: string = 'fa-trash';

    @Output() response = new EventEmitter();

    constructor() { }
    ngOnInit() {
    }

    public userResponse(_response: boolean): void {
        //console.log('ConfirmDeleteComponent => userResponse', _response);
        this.response.emit( _response );
    }

}
