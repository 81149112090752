import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavReportesComponent } from './nav-reportes/nav-reportes.component';
import { NavbarClienteComponent } from './navbar-cliente/navbar-cliente.component';
import { NavbarClienteFooterComponent } from './navbar-cliente-footer/navbar-cliente-footer.component';
import { NavbarFiltroComponent } from './navbar-filtro/navbar-filtro.component';
import { ReactiveFormsModule } from '@angular/forms';

import { NavIconComponent } from './../shared/nav-icon/nav-icon.component';
import { NavIconAdminComponent } from './../shared/nav-icon-admin/nav-icon-admin.component';
import { NavIconWhiteComponent } from './nav-icon-white/nav-icon-white.component';
import { AlertComponent } from './alert/alert.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule, AlertModule} from 'ngx-bootstrap';
import { SmartadminLayoutModule } from './layout';
import { UtilsModule } from "./utils/utils.module";
import { SmartadminWidgetsModule } from "./widgets/smartadmin-widgets.module";
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarModule } from 'ng-sidebar';
import { BsDropdownModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import {FileUploadModule} from 'ng2-file-upload';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
@NgModule({
  declarations: [NavReportesComponent,NavbarClienteComponent, NavbarFiltroComponent, NavbarClienteFooterComponent, NavIconComponent,NavIconAdminComponent, NavIconWhiteComponent, AlertComponent, NavbarComponent],
  exports: [NavReportesComponent,NavbarClienteComponent, NavbarFiltroComponent, NavbarClienteFooterComponent, NavIconComponent,NavIconAdminComponent, NavIconWhiteComponent, AlertComponent,ProgressbarModule,AlertModule,SmartadminLayoutModule,UtilsModule, SmartadminWidgetsModule, NavbarComponent, FormsModule],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ScrollToModule.forRoot(),
    SidebarModule.forRoot(),
    BsDropdownModule.forRoot(),
    FileUploadModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
  ]
})
export class SharedModule { }
