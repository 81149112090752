import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';
import { HttpWebService } from 'src/app/core/http-web.service';
import { ModalService } from '../modal.service';
import { AuthService } from '../../login/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public cart: Cart;

  constructor(private route: Router,
    private fb:FormBuilder,
    private _httpWebService:HttpWebService,
    private authService: AuthService,
    private _modalService:ModalService) { 

      
    }

  getSession = () =>{
      return this.authService.getSession();
  }

  registerCart =(cartProducts:any[], product:any) =>{
    this._httpWebService.post('crucero/registrar_carrito/', this._formatearParametros(cartProducts, product)).then(
      (_rslt:any) => {
         console.log(_rslt);
        if (_rslt.estatus =="ok" && _rslt.data)  {
          this._modalService.setModalInfo("",
          "Los zapatos se agregaron al carrito de compras",true,
          "ver Carrito","/cart","seguir comprando","/tienda/all",true);
          this._modalService.showModal();
        } 
        else {
          this._modalService.setModalInfo("Error al registrar los zapatos",
          _rslt.mensaje,false,
          "Aceptar","","","",true);
        }
      },
      error => {
        this._modalService.setModalInfo("Error",
          "Error al registrar los productos",false,
          "Aceptar","","","",true);        
        console.log("Error al guardar la información en el carrito de compras", error);
       }
    );
  }

  loadDireccionPredeterminada = (result):any => {
    this._httpWebService.get('crucero/get_direccionpredeterminada/').then((_rslt:any) => {
      console.log(_rslt);
        if (_rslt.estatus =="ok")  {
          result(_rslt.data);
        } 
        else {
          result({"direccion": []});
          this._modalService.setModalInfo("Error",
          "Ocurrió un error al recuperar la información",false,
          "Aceptar","/tienda","","",true);
          this._modalService.showModal();
        }

      },
      error => {
        result({"direccion": []});
        this._modalService.setModalInfo("Error",
          "Ocurrió un error al recuperar la información",false,
          "Aceptar","/tienda","","",true);
          this._modalService.showModal();
    });

  }

  generaCotizacion = (carrito:any,direccion:any,result):any => {
    this._httpWebService.get('crucero/generar_cotizacion/?order='+carrito + '&address='+direccion).then((_rslt:any) => {
      console.log(_rslt);
        if (_rslt.estatus =="ok")  {
          result(_rslt.data);
        } 
        else {
          result({});
          this._modalService.setModalInfo("Error",
          "Ocurrió un error al recuperar la información",false,
          "Aceptar","","","",true);
          this._modalService.showModal();
        }

      },
      error => {
        result({});
        this._modalService.setModalInfo("Error",
          "Ocurrió un error al recuperar la información",false,
          "Aceptar","","","",true);
          this._modalService.showModal();
    });

  }

  generaOrden = (orden:any,result):any => {
    this._httpWebService.post('crucero/registra_orden/',orden).then((_rslt:any) => {
      console.log(_rslt);
        if (_rslt.estatus =="ok")  {
          result(_rslt.data);
        } 
        else {
          result(null);
          this._modalService.setModalInfo("Error",
          "Ocurrió un error al recuperar la información",false,
          "Aceptar","","","",true);
          this._modalService.showModal();
        }

      },
      error => {
        result(null);
        this._modalService.setModalInfo("Error",
          "Ocurrió un error",false,
          "Aceptar","","","",true);
          this._modalService.showModal();
    });

  }

  loadCart = (result):any => {
    this._httpWebService.post('crucero/consultar_carrito/', {}).then(
      (_rslt:any) => {
        console.log(_rslt);
        if (_rslt.estatus =="ok")  {
          result(this._createCart(_rslt.data));
        } 
        else {
          console.log("Error => Cart Service => LoadCart1");
          result(this._createCart());
          this._modalService.setModalInfo("Error",
          "No tienes productos en tu carrito, ve a la tienda ",false,
          "Aceptar","/tienda/all","","",true);
          this._modalService.showModal();
        }

      },
      error => {
        console.log("Error => Cart Service => LoadCart2");
        result(this._createCart());
        this._modalService.setModalInfo("Error",
          "No tienes productos en tu carrito, ve a la tienda",false,
          "Aceptar","/tienda/all","","",true);
          this._modalService.showModal();
      });
  }

  eliminaProducto = (detalle:any,result):any => {
    this._httpWebService.post('crucero/eliminar_articulo_carrito/',detalle).then(
      (_rslt:any) => {
        console.log(_rslt);
        if (_rslt.estatus =="ok")  {
          result(this._createCart(_rslt.data));
        } 
        else {
          console.log("Error => Cart Service => LoadCart1");
          result(this._createCart());
          this._modalService.setModalInfo("Error",
          "No se pudo eliminar el producto, intenta de nuevo ",false,
          "Aceptar","","","",true);
          this._modalService.showModal();
        }

      },
      error => {
        console.log("Error => Cart Service => LoadCart2");
        result(this._createCart());
        this._modalService.setModalInfo("Error",
          "No tienes productos en tu carrito, ve a la tienda",false,
          "Aceptar","/tienda","","",true);
          this._modalService.showModal();
      });
  }

  modificaProducto = (detalle:any,result):any => {
    
    this._httpWebService.post('crucero/modificar_articulo_carrito/',detalle).then(
      (_rslt:any) => {
        console.log(_rslt);
        if (_rslt.estatus =="ok")  {
          result(this._createCart(_rslt.data));
        } 
        else {
          console.log("Error => Cart Service => LoadCart1");
          result(this._createCart());
          this._modalService.setModalInfo("Error",
          "No se pudo modificar la cantidad, intenta de nuevo ",false,
          "Aceptar","","","",true);
          this._modalService.showModal();
        }

      },
      error => {
        console.log("Error => Cart Service => LoadCart2");
        result(this._createCart());
        this._modalService.setModalInfo("Error",
          "No tienes productos en tu carrito, ve a la tienda",false,
          "Aceptar","/tienda","","",true);
          this._modalService.showModal();
      });
  }

  private _formatearParametros=(cartProducts:any[], product:any)=>{
    const parametros = {
      "importe": 0,
      "detalle": []
    }
    for (const item of cartProducts) {
      parametros.detalle.push({
        "id":product.id,
        "cantidad":item.cantidad,
        "precio_unitario":this.getPrecioDescuento(product), //getPrecio
        "monto_descuento":this.getMontoDescuento(product),
        "color_id":item.colorID,
        "talla":item.talla
      })
    }
    console.log(parametros);
    return parametros;
    
  }
  getPrecio(product:any) {
    const _session = this.authService.getSession();
    if (_session){
      if (_session.userMayorista || _session.userDistribuidor){
        return (product.precio_mayoreo)
      }else{
        return(product.precio_tienda)
      }
    }else{
      return(product.precio_tienda)
    }
    
  }
  getPrecioDescuento(product:any) {
    const _session = this.authService.getSession();
    if (_session){
      console.log(" getPrecioDescuento sesion: " + _session.mayorista_distribuidor);
      if (_session.mayorista_distribuidor==true){
        if (_session.tipo_usuario=='DISTRIBUIDOR'){
          return (product.descuento_distribuidor.precio_con_descuento)
        }else{
          return (product.descuento_mayoreo.precio_con_descuento)
        }  
      }else{
        return(product.descuento_tienda.precio_con_descuento)
      }
    }else{
      return(product.descuento_tienda.precio_con_descuento)
    }
    
  }

  getMontoDescuento(product:any) {
    const _session = this.authService.getSession();
    if (_session){
      if (_session.mayorista_distribuidor==true){
        if (_session.tipo_usuario=='DISTRIBUIDOR'){
          return (product.descuento_distribuidor.descuento)
        }else{
          return (product.descuento_mayoreo.descuento)
        }  
      }else{
        return(product.descuento_tienda.descuento)
      }
    }else{
      return(product.descuento_tienda.descuento)
    }
    
  }

  private _createCart = (cart?:any):Cart =>{
    const cartObject = new Cart();
    cartObject.id = 0;
    cartObject.sub_total = 0;
    cartObject.envio = 0;
    cartObject.descuento = 0;
    cartObject.total = 0;
    cartObject.products = [];

    if(cart){
      cartObject.total = cart.carrito.importe ; 
      cartObject.id = cart.carrito.id;
      cartObject.descuento =cart.carrito.descuento;
      for (const producto of cart.carrito_detalle) {
        let product = this.createProduct(producto.zapato.id,producto.zapato.foto_principal,
                                         producto.zapato.modelo,
                                         producto.zapato.color_id, producto.color, 
                                         producto.precio_unitario,
                                         [this.createTalla(producto.talla,producto.cantidad,producto.monto_descuento)]);
        this.addProduct(cartObject,product);
      }
    }
    
    this.calculateTotals(cartObject);
    return cartObject;
  }

  createProduct = (id:number,foto:string,modelo:string,colorID:number,color:string,precio:number,tallas:Talla[]): Product => {
    const productObject = new Product();
    productObject.id = id;
    productObject.foto = foto;
    productObject.modelo = modelo;
    productObject.id_color = colorID;
    productObject.color = color;
    productObject.precio = precio;
    productObject.sub_total = 0;
    productObject.tallas = tallas;
    return productObject;
  }


  createTalla = (talla:number,cantidad:number,monto_descuento:number): Talla =>{
    const tallaObject = new Talla();
    tallaObject.talla = talla;
    tallaObject.cantidad = cantidad;
    tallaObject.monto_descuento = monto_descuento;
    return tallaObject;
  }

  addProduct = (cart:any,product:Product) =>{
    console.log(product.id);
    const productIdx = cart.products.findIndex(element => element.id == product.id);
    if(productIdx==-1){
      cart.products.push(product);
    }else{
      const productCart = cart.products[productIdx];
      for (const talla of product.tallas) {
        this.addTalla(productCart,talla);
      }
    }
  }

  private addTalla = (product:Product, talla:Talla) =>{
    const tallaIndex = product.tallas.findIndex(element => element.talla == talla.talla);
    if(tallaIndex==-1){
      product.tallas.push(talla);
    }else{
      const tallaProducto = product.tallas[tallaIndex];
      tallaProducto.cantidad += talla.cantidad;
    }
  }
  
  calculateTotals = (cartObject:any) =>{
    console.log(cartObject);
    cartObject.sub_total = 0;
    cartObject.total = 0;

    for (let product of cartObject.products) {
      product.sub_total = 0;
      for (let talla of product.tallas) {
        product.sub_total += (talla.cantidad*product.precio) + (talla.cantidad*talla.monto_descuento);          
      }
      cartObject.sub_total += product.sub_total;
    }
    cartObject.total +=cartObject.sub_total + cartObject.envio - cartObject.descuento; 
        
  } 
  
}

export class Cart{
  id        : number;
  sub_total: number;
  envio: number;
  descuento: number;
  total: number;
  products: Product[];
}

export class Product{
  id        : number;
  foto      : string;
  modelo    : string;
  id_color  : number;
  color     : string;
  precio    : number;
  sub_total : number;
  tallas    : Talla[];
}

export class Talla{
  productID: number;
  talla: number;
  cantidad: number;
  monto_descuento: number;
}