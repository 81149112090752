import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { HttpWebService } from '../core/http-web.service';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.css']
})
export class NovedadesComponent implements OnInit {
  isCollapsed = false;
  novedad_id:string = "";

  constructor(private _scrollToService: ScrollToService, private modalService: BsModalService,private HttpWebService: HttpWebService, private route: Router,
    private rutaActiva: ActivatedRoute,) {
      this.rutaActiva.params.subscribe( parametros =>{
        this.validateParams(parametros["parametro"]); 
      })
     }

  novedad_single: any = "";  
  ngOnInit() {  
    this.loadNovedades();
  }

  
  loadNovedades = ()=>{
    /* conexion */   
    this.HttpWebService.getNoHeaderAuthorization('crucero/consulta_novedad/?novedad='+this.novedad_id).then((response) => {
      //console.log(response);
      this.novedad_single = response.data.novedad;      
      
    })
  }

  validateParams = (param:string) =>{
    if(param===""){
      console.log("No se seleccionaron zapatos para comparar");
    }else{
      this.novedad_id = param;
    }
  }
  

  public triggerScrollTo() {
    
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
 
    this._scrollToService.scrollTo(config);
  }

}
