import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import filters from '../../../assets/json/filters.json';

@Component({
  selector: 'app-nav-filtro',
  templateUrl: './navbar-filtro.component.html',
  styleUrls: ['./navbar-filtro.component.css']
})
export class NavbarFiltroComponent implements OnInit {
  filtros: any;
  @Output() filtroSeleccionado = new EventEmitter<{event:any, selectedID:string}>();

  constructor() { }

  ngOnInit() {
    this.filtros = filters.filters;
    console.log(this.filtros);
  }

  onChecked = (event: any, id:string) =>{
      console.log(id + " " + (event.target.checked ? 1:0));
      this.filtroSeleccionado.emit({event, selectedID:id});          
  }
  
}