// jQuery

/* declare var jQuery: any; */
declare var jquery: any;

// Smartadmin Dependencies 

// import 'jquery-ui-npm/jquery-ui.min.js'


// import 'imports-loader?jQuery=jquery!jquery-color/jquery.color.js'

require('smartadmin-plugins/notification/SmartNotification.min.js');
