import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpWebService } from '../../app/core/http-web.service'
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../app/login/auth.service';
import { AlertService } from "../../app/shared/layout/alerts/alert.service";
import { NotificationService } from '../../app/shared/utils/notification.service';
@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.css']
})
export class LoginUserComponent implements OnInit {

  public muestraerror: boolean;
  public user: string;
  public password: string;
  public loading: any;
  public data: any;
  radioModel = 'Cliente';
  public disabled: boolean = false;

  constructor(
    private HttpWebService: HttpWebService,
    private authService: AuthService,
    public http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit() {
  }

  public login(): void {
    this.authService.loginCliente(this.user, this.password).then(
      (_rslt: boolean | any) => {
        if (_rslt == true)  {
          this.notificationService.successSmallBox("Credenciales correctas!. Bienvenido");
          setTimeout(() => {
            this.router.navigate(['/user/home']);
           
          }, 1000);
          return _rslt;
        } 
        else {
          this.notificationService.errorBigBox(_rslt.messageError);
         /*  this.notificationService.errorBigBox(_rslt.messageError); */
          console.log("holaaaaaa", _rslt);
        }
      },
      /*  error => {
         this.error = error;
         this.alertService.hideProgressBar();
         window.alert("credenciales incorrectas")
       } */
    );
  }

  private _errorResponse(typeError: number): void {
    let errorMessage: string = null;
    this.disabled = false;
    ///NO EXISTE EL USUARIO
    if (typeError == 1) {
      //this.isModalErrorConfirmationShow = true;
      errorMessage = 'error_login_no_existe_usuario';
    }
    //USUARIO INACTIVO
    else if (typeError == 2) {
      errorMessage = 'error_login_inactivo';
    }
    //SESION ACTIVA POR OTRA PERSONA
    else if (typeError == 3) {
      errorMessage = 'error_login_sesion_activa';
    }
    //VIGENCIA 
    else if (typeError == 4) {
      errorMessage = 'error_login_vigencia';
    }
    else {
      errorMessage = 'Password y/o usuario incorrectos. Intenta nuevamente';
    }

    this.notificationService.smartMessageBox(
      {
        title: `<i class="fa fa-lock text-danger"></i>&nbsp;<span class='text-danger'><strong>` + 'error' + `</strong></span>`,
        content: errorMessage,
        buttons: '[Ok]'
      },
      buttonPressed => {
        if (buttonPressed == "Ok") {
        }
      }
    );

  }
}
