import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public hide: boolean = true;
  public title: string = '';
  public message: string = '';
  public success: boolean = true;
  public okButton: string = '';
  public routeOKButton: string = '';
  public routeCancelButton: string = '';
  public cancelButton: string = '';
  public showCloseImage: boolean = true;

  get ishidden() {
    return this.hide;
  }

  getTitle = () =>{
    return this.title;
  }

  showModal() {
    this.hide = false;
  }

  hideModal() {
    this.hide = true;
  }

  setModalInfo = (title:string,message:string,success:boolean,
                  okButton:string,routeOKButton:string,
                  cancelButton:string, routeCancelButton:string,
                  showCloseImage:boolean) =>{
    this.title = title;
    this.message = message;
    this.success = success;
    this.okButton = okButton;
    this.routeOKButton = routeOKButton;
    this.cancelButton = cancelButton;
    this.routeCancelButton = routeCancelButton;
    this.showCloseImage = showCloseImage;
  }

  okAction = () =>{
    this.hideModal();
    if(this.routeOKButton===''){
    }else{
      this.router.navigate([this.routeOKButton]);
    }
    
  }

  cancelAction = () =>{
    this.hideModal();
    if(this.routeCancelButton===''){
    }else{
      this.router.navigate([this.routeCancelButton]);
    }
    
  }

  constructor(private router: Router) { }
}
