import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpWebService } from '../core/http-web.service';
import { AuthService } from '../login/auth.service';
import { UserService } from './user.service';
import { DireccionService } from '../services/direccion.service';
import { FacturacionService } from '../services/facturacion.service';
import { ModalService } from '../services/modal.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('closePasswordModal') closePasswordModal;
  @ViewChild('closeFacturacionModal') closeFacturacionModal;
  @ViewChild('closeDireccionModal') closeDireccionModal;


  user:any;
  facturacion: any;
  ordenSeleccionada: any;
  direcciones: any[] = [];
  ordenes: any[] = [];
  passwordMismatch: boolean = false;

  loading:boolean = true;

  facturacionForm:FormGroup;
  editFacturacion:boolean = false;

  direccionForm:FormGroup;
  editDireccion:boolean = false;
  
  constructor(private _httpWebService:HttpWebService,
              private _authService:AuthService,
              public userService:UserService,
              public direccionService:DireccionService,
              public facturacionService:FacturacionService,
              private _modalService: ModalService,
              private _router: Router,
              private _fb:FormBuilder) { 
    
    this.loadUserInfo();
  }

  loadUserInfo = () =>{
    this._httpWebService.get('crucero/consulta_usuario/').then((response) => {
      this.user = response.data.datos_usuario;
      this.facturacion = response.data.datos_facturacion;
      this.direcciones = response.data.datos_direccion;
      this.ordenes = response.data.ordenes;
      console.log("user " + this.user.usuario_nombre);
      console.log("direcciones " + this.direcciones.length);
      console.log("facturacion " + this.facturacion);
      console.log("Ordenes " + this.ordenes);

      if (this.ordenes != []){
        this.ordenSeleccionada = this.ordenes[0];
      }

      console.log("ordenSeleccionada " + this.ordenSeleccionada);
      
      this.createDireccionForm();
      if(this.facturacion && this.facturacion.rfc){
        this.editFacturacion=true;
        this.createFacturacionForm(this.facturacion);
      }else{
        this.createFacturacionForm();
      }
      this.loading = false;
      console.log("usuario: " + this.user);
    });

  }

  ngOnInit() {
  }

  changePassword = () => {
    if(this.userService.passwordForm.invalid){
      return this.userService.showValidations(this.userService.passwordForm);
    }else{
      this.passwordMismatch = this.userService.passwordMismatch();
      console.log(this.passwordMismatch);
      if(!this.passwordMismatch){
        this._authService.changePassword(this.userService.passwordForm.get("password").value,
                                         this.userService.passwordForm.get("confirmPassword").value).then((response) => {
          
          this.closePasswordModal.nativeElement.click();
          console.log("cerrando Modal");
          console.log("Response" + response);
          if(response){
            this._modalService.setModalInfo("Información",
            "El password se actualizó correctamente (inicie sesión nuevamente)",true,
            "","","Aceptar","/login",false);
            this._modalService.showModal();
          }else{
            this._modalService.setModalInfo("Información",
            "Ocurrió un error al actualizar la información",false,
            "","","Aceptar","",false);
            this._modalService.showModal();
          }
        })
      }else{
        console.log(this.userService.passwordForm);
      }      
    }
  }
  
  showValidations = (form:FormGroup) =>{
    if(form.invalid){
      return Object.values(form.controls ).forEach( control => {
        control.markAsTouched();
      })
    }
  }

  obtenOrden = (indice:any) =>{
    this.ordenSeleccionada = this.ordenes[indice];
  }

  guardarFacturacion = () => {
    if(this.facturacionForm.invalid){
      console.log("Formulario es inválido");
      console.log(this.facturacionForm)
      return this.showValidations(this.facturacionForm);
    }else{
      const result = this.facturacionService.save(this.facturacionForm,this.editFacturacion, (error)=>{
        this.closeFacturacionModal.nativeElement.click();
        console.log(error);
        if(!error){
          this._modalService.setModalInfo("Información",
              "La información de facturación se guardo correctamente",true,
              "","","Aceptar","",false);
              this._modalService.showModal();
              this.loadUserInfo();
        }else{
          this._modalService.setModalInfo("Información",
              "Ocurrió un error al guardar la información",false,
              "","","Aceptar","",false);
              this._modalService.showModal();
        }
      });
      
    }
  }

  guardarDireccion = () => {
    console.log(this.direccionForm)
    if(this.direccionForm.invalid){
      console.log("Formulario es inválido");
      console.log(this.direccionForm)
      return this.showValidations(this.direccionForm);
    }else{
      const result = this.direccionService.save(this.direccionForm,this.editDireccion, (error)=>{
        this.closeDireccionModal.nativeElement.click();
        console.log(error);
        if(!error){
          this._modalService.setModalInfo("Información",
              "La Dirección se guardo correctamente",true,
              "","","Aceptar","",false);
              this._modalService.showModal();
              this.loadUserInfo();
        }else{
          this._modalService.setModalInfo("Información",
              "Ocurrió un error al guardar la información",false,
              "","","Aceptar","",false);
              this._modalService.showModal();
        }
      });
      
    }
  }

  eliminaDireccion = (direccion:any) => {
      const result = this.direccionService.elimina(direccion, (result)=>{
        if(result){
          this.direcciones = result.direccion;
        }else{
          this._modalService.setModalInfo("Información",
              "Ocurrió un error al consultar la información",false,
              "","","Aceptar","",false);
              this._modalService.showModal();
        }
      });
  }

  setPredeterminada = (direccion:any) => {
    const result = this.direccionService.set_predeterminada(direccion, (result)=>{
      if(result){
        this.direcciones = result.direccion;
      }else{
        this._modalService.setModalInfo("Información",
            "Ocurrió un error al consultar la información",false,
            "","","Aceptar","",false);
            this._modalService.showModal();
      }
    });
}

  cambiaMunicipios = (selectedValue:number) => {
    if(selectedValue){
      //cambiar valor del municipio
      console.log(selectedValue);
      this.direccionService.cambiaMunicipios(selectedValue);  
    }
  }

  cerrarSesion = () =>{
    this._authService.closeSession();
    this._router.navigate(['/login']);
  }

  invalidFacturacionField = (field:string) =>{
    return (this.facturacionForm.get(field).invalid && this.facturacionForm.get(field).touched);
  }

  invalidDireccionField = (field:string) =>{
    return (this.direccionForm.get(field).invalid && this.direccionForm.get(field).touched);
  }

  createFacturacionForm = (facturacion?:any) => {
    console.log("Facturación: " + facturacion);
    if(facturacion){
      this.facturacionForm = this._fb.group({
        razonSocial:[facturacion.razon_social,[Validators.required]],
        rfc: [facturacion.rfc,[Validators.required]],
        usoFactura: [facturacion.usocfdi_id,[Validators.required]],
        direccion: [facturacion.calle_numero,[Validators.required]],
        codigoPostal: [facturacion.cp,[Validators.required]],
        colonia: [facturacion.colonia,[Validators.required]],
        estado: [facturacion.estado_id,[Validators.required, Validators.min(1)]],
        municipio: [facturacion.municipio_id,[Validators.required, Validators.min(1)]]   
      });
      this.cambiaMunicipios(facturacion.estado_id);
    }else{
      this.facturacionForm = this._fb.group({
        razonSocial:['',[Validators.required]],
        rfc: ['',[Validators.required]],
        usoFactura: [0,[Validators.required]],
        direccion: ['',[Validators.required]],
        codigoPostal: ['',[Validators.required]],
        colonia: ['',[Validators.required]],
        estado: [0,[Validators.required, Validators.min(1)]],
        municipio: [0,[Validators.required, Validators.min(1)]]
      });
    }
  }

  createDireccionForm = (direccion?:any) => {
    console.log("Dirección: " + direccion);
    if(direccion){
      this.direccionForm = this._fb.group({
        nombre:[direccion.nombre,[Validators.required]],
        calle: [direccion.calle,[Validators.required]],
        numero: [direccion.numero,[Validators.required]],
        codigoPostal: [direccion.cp,[Validators.required]],
        colonia: [direccion.colonia,[Validators.required]],
        estado: [direccion.estado_id,[Validators.required, Validators.min(1)]],
        municipio: [direccion.municipio_id,[Validators.required, Validators.min(1)]],
        referencia: [direccion.referencia,[Validators.required]],
        predeterminada: [direccion.predeterminada] 
      });
      this.cambiaMunicipios(direccion.estado_id);
    }else{
      this.direccionForm = this._fb.group({
        nombre:['',[Validators.required]],
        calle: ['',[Validators.required]],
        numero: ['',[Validators.required]],
        codigoPostal: ['',[Validators.required]],
        colonia: ['',[Validators.required]],
        estado: [0,[Validators.required, Validators.min(1)]],
        municipio: [0,[Validators.required, Validators.min(1)]],
        referencia: ['',[Validators.required]],
        predeterminada: [false]        
      });
    }
  }
}
