import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HttpWebService } from '../core/http-web.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class DireccionService {

  direccionForm:FormGroup;
  estados:any[] = [];
  municipios: any[] = [];
  direccion_guardada: any;

  constructor(private _httpWebService:HttpWebService,private _modalService:ModalService) {

    this.getEstados();
   }


  getEstados = () =>{
    this._httpWebService.get('modelos_compartidos/get_estados/').then((response) => {
      this.estados = response.data;
    })
  }

  getMunicipios = (estadoID:number) =>{
    this._httpWebService.get('modelos_compartidos/get_municipios/?estado=' + estadoID).then((response) => {
      this.municipios = response.data;
    })
  }

  cambiaMunicipios = (selectedValue:number) =>{
    this.getMunicipios(selectedValue);
  }

  save = (direccionForm:FormGroup,editDireccion:boolean,error) =>{
    let method = "registrar_direccion/";

    if(editDireccion){
      method = "editar_direccion/"
    }

    this._httpWebService.post('crucero/' + method,this.getParameters(direccionForm)).then((response) => {
      if(response){
        if(response.estatus=="ok"){
          this.direccion_guardada = response.data.direccion;
          error(false);
        }else{
          error(true);
        }
      }else{
        error(true);
      }
    })
    
  }

  elimina = (direccion:any,result) =>{
    let method = "eliminar_direccion/";
    this._httpWebService.post('crucero/' + method,{"direccion":direccion}).then((response) => {
      if (response.estatus =="ok")  {
        result(response.data);
      } 
      else {
        result(response.data);
        this._modalService.setModalInfo("Error",
        "Ocurrió un error al eliminar la dirección",false,
        "Aceptar","/user-profile","","",true);
        this._modalService.showModal();
      }

    },
    error => {
      result({"direccion": []});
      this._modalService.setModalInfo("Error",
        "Ocurrió un error al recuperar la dirección",false,
        "Aceptar","/user-profile","","",true);
        this._modalService.showModal();
    });
    
  }

  set_predeterminada = (direccion:any,result) =>{
    let method = "set_direccionpredeterminada/";
    this._httpWebService.post('crucero/' + method,{"direccion":direccion}).then((response) => {
      if (response.estatus =="ok")  {
        result(response.data);
      } 
      else {
        result(response.data);
        this._modalService.setModalInfo("Error",
        "Ocurrió un error al eliminar la dirección",false,
        "Aceptar","/user-profile","","",true);
        this._modalService.showModal();
      }
    },
    error => {
      result('error');
      this._modalService.setModalInfo("Error",
        "Ocurrió un error al recuperar la dirección",false,
        "Aceptar","/user-profile","","",true);
        this._modalService.showModal();
    });
    
  }

  getParameters = (direccionForm:FormGroup) =>{
    return {
      "nombre": direccionForm.get("nombre").value,
      "calle": direccionForm.get("calle").value,
      "numero": direccionForm.get("numero").value,
      "colonia": direccionForm.get("colonia").value,
      "municipio_id": direccionForm.get("municipio").value,
      "estado_id": direccionForm.get("estado").value,
      "cp": direccionForm.get("codigoPostal").value,
      "predeterminada": direccionForm.get("predeterminada").value,
      "referencia": direccionForm.get("referencia").value,
    }
  }

  loadDirecciones = (result):any => {
    this._httpWebService.get('crucero/get_direcciones/').then((_rslt:any) => {
      console.log(_rslt);
        if (_rslt.estatus =="ok")  {
          result(_rslt.data);
        } 
        else {
          result({"direccion": []});
          this._modalService.setModalInfo("Error",
          "Ocurrió un error al recuperar la información",false,
          "Aceptar","/tienda","","",true);
          this._modalService.showModal();
        }

      },
      error => {
        result({"direccion": []});
        this._modalService.setModalInfo("Error",
          "Ocurrió un error al recuperar la información",false,
          "Aceptar","/tienda","","",true);
          this._modalService.showModal();
    });

  }


}
