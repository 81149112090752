import { LoginUserComponent } from './login-user/login-user.component';
import { SplashscreenComponent } from './splashscreen/splashscreen.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { RegisterUserComponent } from './register-user/register-user.component';

import { CuentasComponent } from './cuentas/cuentas.component';
import { RecuperarPasswordComponent } from './recuperar-password/recuperar-password.component';


//crucero
import { InicioComponent } from './inicio/inicio.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { TiendaComponent } from './tienda/tienda.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ComparadorComponent } from './comparador/comparador.component';
import { DetalleProductoComponent } from './detalle-producto/detalle-producto.component';
import { CartComponent } from './cart/cart.component';
import { AuthGuard } from './guards/auth.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NovedadesComponent } from './novedades/novedades.component';




const routes: Routes = [
  /* {
    path: '',
    component: SplashscreenComponent,
  }, */
  {
    path: '',
    component: InicioComponent
  },
  {
    path: 'nosotros',
    component: NosotrosComponent
  },
  {
    path: 'novedades/:parametro',
    component: NovedadesComponent
  },
  {
    path: 'catalogo/:parametro',
    component: CatalogoComponent
  },
  {
    path: 'tienda/:palabra',
    component: TiendaComponent
  },
  {
    path: 'contacto',
    component: ContactoComponent
  },

  {
    path: 'comparador/:ids',
    component: ComparadorComponent
  },
  
  {
    path: 'detalle-producto/:modelo',
    component: DetalleProductoComponent,
    /*canActivate: [AuthGuard]*/
  },

  {
    path: 'home',
    component: HomeComponent
  },
  
  { 
    path: 'cuentas',
    component: CuentasComponent 
  },
  
  {
    path: 'reportes',
    loadChildren: './reportes/reportes.module#ReportesModule',
  },

  { path: 'login', component: LoginComponent },
  { path: 'cart', component: CartComponent, canActivate: [AuthGuard] },
  { path: 'login-user', component: LoginUserComponent},
  
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'recuperar-password', component: RecuperarPasswordComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'register-user', component: RegisterUserComponent },
  
  { path: 'user-profile', component: UserProfileComponent },
  {
    path: 'user',
    loadChildren: './user/user.module#UserModule',
  },

  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
