import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FadeZoomInTop } from "../../animations/fade-zoom-in-top.decorator";

import { AlertService } from '../alerts/alert.service';
import { LoginComponent } from 'app/auth/login/login.component';

import { BsDatepickerModule } from 'ngx-bootstrap/';

@FadeZoomInTop()
@Component({
  selector: 'app-recursos-humanos-layout',
  templateUrl: './recursos-humanos-layout.component.html',
  styles: []
})
export class RecursosHumanosLayoutComponent implements OnInit, OnDestroy {


  private alertServiceProgressBarSubscription: Subscription;
  public alertProgressBar: any = {
    show: false,
    data: {}
  }

  constructor(
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.alertServiceProgressBarSubscription = this.alertService.getProgressBarObservable().subscribe(
      _rslt => {
        //console.log(_rslt);
        this.alertProgressBar.show = _rslt.show;
        if (_rslt.data) {
          this.alertProgressBar.data = _rslt.data;
        }
      }
    );
  }

  public onActivate(event, outlet) {
    //console.log('onActivate event', event);
    // console.log('NO SIRVE PORQUE NO TIENE ALIAS onActivate outlet', outlet);
    window.scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.alertServiceProgressBarSubscription.unsubscribe();
  }

}
