import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { HttpWebService } from '../../app/core/http-web.service'
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../app/login/auth.service';
import { AlertService } from "../../app/shared/layout/alerts/alert.service";
import { NotificationService } from '../../app/shared/utils/notification.service';
@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.css']
})
export class RecuperarPasswordComponent implements OnInit {
  public pattern: any;
  public password: string;
  public confirmPassword: string;
  public id: any;

  constructor(
    private HttpWebService: HttpWebService,
    private authService: AuthService,
    public http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
  ) {
    this.pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.{8,}$)/;

    this.route.queryParams.subscribe(params => {
      this.id = params['id'];   //<----- + sign converts string value to number
      
      console.log("este es el id obtenido por url",this.id);
    });
  }

  ngOnInit() {

  }

  public restablece(): void {
    this.authService.restablece(this.id, this.password).then(
      (_rslt: boolean | any) => {
        this.alertService.hideProgressBar();
        if (_rslt === true) {
          this.notificationService.successSmallBox("Registro de nueva contraseña exitosamente");
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000);
          return _rslt;
        }
        else {
          this.notificationService.errorBigBox(_rslt.messageError);
          console.log("holaaaaaa", _rslt.messageError);
        }
      },
      /*  error => {
         this.error = error;
         this.alertService.hideProgressBar();
         window.alert("credenciales incorrectas")
       } */
    );
  }

}
