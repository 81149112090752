import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-icon-white',
  templateUrl: './nav-icon-white.component.html',
  styleUrls: ['./nav-icon-white.component.css']
})
export class NavIconWhiteComponent implements OnInit {
  isCollapsed = true;
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService, private _scrollToService: ScrollToService, private router: Router,) { }

  ngOnInit() {
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  openModalReg(template_reg: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template_reg);
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  loginConcesionario(){
     this.router.navigate(['/login']);
     this.modalRef.hide();
  }
  loginUser(){
    this.router.navigate(['/login-user']);
    this.modalRef.hide();
 }

 registerUser(){
  this.router.navigate(['/register-user']);
  this.modalRef.hide();
}
registerConce(){
  this.router.navigate(['/register']);
  this.modalRef.hide();
}
  public triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
    this._scrollToService.scrollTo(config);
  }
}
