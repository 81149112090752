import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders, HttpClient } from '@angular/common/http';
/* import { appConfig } from '../../core/core.config'; */
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
    ) {
        // console.log('AuthInterceptor->constructor()');
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('AuthInterceptor->intercept()');
        // // Obtenemos el token
        // const _tokenAuth = this.authService.getTokenAuth();
        // // Importante: modificamos de forma inmutable, haciendo el clonado de la petición
        // request = request.clone({
        // setHeaders: {
        //     Authorization: _tokenAuth
        // }
        // });
        // Pasamos al siguiente interceptor de la cadena la petición modificada
        return next.handle(request).do(
            (event: HttpEvent<any>) => {
                //console.log('intercept event', event);
                if (event instanceof HttpResponse) {
                    // do stuff with response if you want
                    console.log("Este e el event login:", event);
                    if (event.body.response == 403) {
                        this.router.navigate(['/login']);
                    }
                }
            },
            (error: any) => {
                console.log('intercept err', error);
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 403) {
                        console.log(' if (error.status === 403)', error);
                        // redirect to the login route
                        //this.router.navigate([appConfig.LOGIN_ROUTE]);
                        this.router.navigate(['/login']);
                        // or show a modal
                    } else if (error.status === 0) {
                        console.log(' if (error.status === 0)', error);
                        /* alert('ERR_INTERNET_DISCONNECTED'); */
                    }
                }
            },
            
        );
    }
}