import { Component, OnInit,TemplateRef, ElementRef } from '@angular/core';
import { HttpWebService } from '../../core/http-web.service';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm, AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-nav-cliente-footer',
  templateUrl: './navbar-cliente-footer.component.html',
  styleUrls: ['./navbar-cliente-footer.component.css']
})
export class NavbarClienteFooterComponent implements OnInit {
  boletinForm: FormGroup;
  constructor(private HttpWebService: HttpWebService,
    private route: Router,private fb: FormBuilder,
    private modalService: ModalService,
    private el: ElementRef,
    private httpWebService: HttpWebService,) { }

  ngOnInit() {
    this.createBoletinForm();
  }

  linkClick(parametro: string) {
    console.log("linkClick:");
     this.route.navigate(['/catalogo',parametro]);
  }
  navigateToSection(sectionId: string): void {
    this.route.navigate([''], { fragment: sectionId });
  }
  scrollToTop() {
    
    const element = this.el.nativeElement;
    //window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  invalidUserField = (field:string) =>{
    return (this.boletinForm.get(field).invalid && this.boletinForm.get(field).touched);
  }

  createBoletinForm = () => {
    this.boletinForm = this.fb.group({
      email: ['',[Validators.required, Validators.email]]
    });
  }

  showValidations = (form:FormGroup) =>{
    if(form.invalid){
      return Object.values( form.controls ).forEach( control => {
        control.markAsTouched();
      })
    }
  }

  formatearParametros=()=>{
    const parametros = {
      "email": this.boletinForm.get("email").value
      
    }
    return parametros;
    
  }

  public registerBoletin(): void {

    if(this.boletinForm.invalid){
      return this.showValidations(this.boletinForm);
    }else{
    this.httpWebService.post('crucero/registrar_boletin/', this.formatearParametros()).then(
      (_rslt:any) => {
        if (_rslt.estatus =="ok" && _rslt.data)  {
          this.modalService.setModalInfo("Registro exitoso",
            "Espere noticias de nuestro boletín.",true,
            "","","Aceptar","/",true);
  
          this.modalService.showModal();
          setTimeout(() => {
            this.modalService.hideModal();
            this.route.navigate(['/']);  
            this.scrollToTop();       
          }, 2000);
          
        } 
        else {
          this.modalService.setModalInfo("Error al enviar su correo electrónico",
          _rslt.mensaje,false,
            "","","Aceptar","",true);
          this.modalService.showModal();
        }
      },
      error => {
        this.modalService.setModalInfo("Error al enviar su correo electrónico",
        "Ocurrió un error al registrar la información",false,
            "","","Aceptar","",true);
        
            this.modalService.showModal();
        console.log("Error al registar su correo electrónico", error);
       }
    );
  }
}

}