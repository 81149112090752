import { Component, OnInit, OnDestroy, TemplateRef, AfterViewInit, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap';
import { HttpWebService } from '../../../app/core/http-web.service';
import { AuthService } from '../../../app/login/auth.service';
import { AlertService } from "../../../app/shared/layout/alerts/alert.service";
import { NotificationService } from '../../../app/shared/utils/notification.service';
import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';


import { NavIconComponent } from '../../shared/nav-icon/nav-icon.component';
import { Subject } from 'rxjs';

import { DomSanitizer, SafeUrl, ɵDomSanitizerImpl, SafeHtml } from '@angular/platform-browser';
import { DataTableDirective } from 'angular-datatables';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {esLocale} from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';



import { ModalDirective } from 'ngx-bootstrap/modal';
import Stepper from 'bs-stepper';

const URL = 'https://api.cicili.com.mx:8443/app/concesionario/archivo/guardar';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  /*private stepper: Stepper;
  @ViewChild('autoShownModal', { }) autoShownModal: ModalDirective;
  isModalShown = true;*/

  completaDir: boolean = true
  /* dropzone */
  public uploader: FileUploader = new FileUploader({
    url: URL,
    authToken: this.authService.getTokenAuth()
  });
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;
  status: any;
  form: any;
  guardadir: any;
  idConcesionario: any;
  nombre: any;
  apellidoMaterno: any;
  apellidoPaterno: any;
  descripcion: any;
  telefono: any;
  facturasPendientes:number;


  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
  /* dropzone */
  public currentUser: any;
  public current: any;
  public imagen: any = [];

  public correoElectronico: any;
  public disabled: boolean = false;

  modalRef: BsModalRef | null;
  direccion: any;
  pais: any;
  selectedPais: any;
  estado: any;
  municipio: any;
  asentamiento: any;
  calle: any;
  cp: any;
  interior: any;
  exterior: any;
  guardarDir: any;
  archivo: any;
  constructor(
    private HttpWebService: HttpWebService,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
  ) {



  }

  public _pais(): Promise<any> {
    return this.HttpWebService.get('app/catalogos/paises');
  }

  public _estado(paisId: number): Promise<any> {
    return this.HttpWebService.get('app/catalogos/estados/' + paisId);
  }

  public _municipio(estadoid: number): Promise<any> {
    return this.HttpWebService.get('app/catalogos/municipios/' + estadoid);
  }

  public _facturasPendientes(): Promise<any> {
    return this.HttpWebService.get('app/concesionario/facturaspendientes/');
  }

  public _asentamiento(municipioid: number): Promise<any> {
    return this.HttpWebService.get('app/catalogos/asentamientos/' + municipioid);
  }

  onChangePais(paisId: number) {
    if (paisId) {
      this._estado(paisId).then(rslt => {
        this.estado = rslt.data;
        this.municipio = null;
        this.asentamiento = null;
      });
    } else {
      this.estado = null;
      this.municipio = null;
      this.asentamiento = null;
    }
  }

  onChangeEstado(estadoid: number) {
    if (estadoid) {
      this._municipio(estadoid).then(rslt => {
        this.municipio = rslt.data
        this.asentamiento = null;
      });
    } else {
      this.municipio = null;
      this.asentamiento = null;
    }
  }

  onChangeMunicipio(municipioid: number) {
    if (municipioid) {
      this._asentamiento(municipioid).then(rslt => {
        this.asentamiento = rslt.data;
      });
    } else {
      this.asentamiento = null;
    }
  }

  openModalEdit(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-dialog-45' })
    );
  }

  openModalagregar(templateAgregar: TemplateRef<any>) {

    this.modalRef = this.modalService.show(
      templateAgregar,
      Object.assign({}, { class: 'modal-dialog-45' })
    );
  }
 
  openModalDocs(templateDocs: TemplateRef<any>) {

    this.modalRef = this.modalService.show(
      templateDocs,
      Object.assign({}, { class: '' })
    );
  }
  ngOnInit() {
    /*this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })*/
    this.idConcesionario = JSON.parse(localStorage.getItem('idConcesionario'));
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    }

    this._usuarios().then(response => {
      this.current = response.data;
     
      /* this.current = JSON.parse(localStorage.getItem('current')); */
      console.log('usuario actual:' + this.current);
      console.log(response);
      if (response) {

      }
      else {

      }
    });

    this._califica().then(response => {
      this.nombre = response.data.nombre;
      this.imagen = response.data.imagen;
      this.correoElectronico = response.data.correoElectronico;
      this.status = response.data.status;
      /*  this._sessionServer = localStorage.getItem('usuario'); */
      console.log("Obten el estatus:" + this.status);

      if (response) {



      }
      else {

      }
    });
    this._facturasPendientes().then(rslt => {
      this.facturasPendientes = rslt.data;
    });
/* 
    this._usuarios().then(rslt => {
      this.current = JSON.parse(localStorage.getItem('current'));
      console.log('usuario actual:' + localStorage.getItem('current'));
      console.log(rslt);
      if (rslt) {

      }
      else {

      }
    }); */
/* 
    this._califica().then(rslt => {
      this.users = JSON.parse(localStorage.getItem('usuario'));
      this.imagen = JSON.parse(localStorage.getItem('imagen'));
      this.correoElectronico = JSON.parse(localStorage.getItem('correoElectronico'));
      this.status = JSON.parse(localStorage.getItem('status'));
   
      console.log("Obten el estatus:" + this.status);

      if (rslt) {



      }
      else {

      }
    }); */

    this._direccion().then(rslt => {
      this.direccion = rslt.data;
      console.log("direccion:" + rslt.data);
      if (rslt.data) {
        this.calle = rslt.data.calle;
        this.interior = rslt.data.interior;
        this.exterior = rslt.data.exterior;
        this.cp = rslt.data.asentamiento.cp;
        this.pais = rslt.data.asentamiento.municipio.estado.pais.id;
        this.estado = rslt.data.asentamiento.municipio.estado.id;
        this.municipio = rslt.data.asentamiento.municipio.id;
        this.asentamiento = rslt.data.asentamiento.id;
      }

    });

    /* catalogo de paises estados ext */
    this._pais().then(rslt => {
      this.pais = rslt.data;

    });
  }


  public setDataLocalStorageSession(_data: any): void {
  }

  public _califica(): Promise<any> {
    return this.HttpWebService.get('app/concesionario/' + this.idConcesionario);
  }

  guardar(current) {
    console.log(current);
    this.HttpWebService.post('app/concesionario/actualizar', current).then((response) => {

      console.log('repsonse:', response);
      if (response.status === 200 && response.data.error) {
        this.alertService.hideProgressBar();
        this._errorResponse(5);
      }
      else {
        this.notificationService.successBigBox("Actualización de concesionario exitosa.");
        this.router.navigateByUrl('/login');
        this.modalRef.hide();
        return response;
      }
    });
  }

  guardarDireccion(form) {

    this.form = {
      calle: this.calle,
      interior: this.interior,
      exterior: this.exterior,
      asentamiento: this.asentamiento.id,
    }
    /* this.alertService.showProgressBar('validando..', 'fa-sign-in'); */
    this.HttpWebService.post('app/concesionario/direccion', form).then((response) => {
      console.log('repsonse editar conductor:', response);
      /*  this.router.navigateByUrl('/conductores'); */
      this.notificationService.successBigBox("Registro de asignacion de conductor exitosamente");
      this.modalRef.hide();
    });

/*     this.HttpWebService.post('app/concesionario/direccion', guardadir.value).then((response) => {
      console.log('repsonse:', response);
      if (response.status === 200 && response.data.error) {
        this.alertService.hideProgressBar();
        this._errorResponse(5);
      }
      else {
        this.notificationService.successBigBox("Actualización de concesionario exitosa.");
        return response;
      }
    }); */
  }

  public _usuarios(): Promise<any> {
    return this.HttpWebService.get('app/concesionario/' + this.idConcesionario);
  }

  public _direccion(): Promise<any> {
    return this.HttpWebService.get('app/concesionario/direccion/');
  }

  public _direccionGuardar(): Promise<any> {
    return this.HttpWebService.post('app/concesionario/direccion/');
  }

  logOut() {
   
      localStorage.removeItem('sessionServer');
      localStorage.removeItem('current');
      localStorage.removeItem('tokenAuth');
      localStorage.removeItem('timeSession');
      localStorage.removeItem('usuario');
      localStorage.removeItem('correo');
      localStorage.removeItem('status');
      localStorage.removeItem('idConcesionario');
      localStorage.removeItem('session');
      localStorage.removeItem('imagen');
      localStorage.removeItem('correoElectronico');

      this.router.navigate(['login']);
    


  }
  private _errorResponse(typeError: number): void {
    let errorMessage: string = null;
    this.disabled = false;
    ///NO EXISTE EL USUARIO
    if (typeError == 1) {
      //this.isModalErrorConfirmationShow = true;
      errorMessage = 'error_login_no_existe_usuario';
    }
    //USUARIO INACTIVO
    else if (typeError == 2) {
      errorMessage = 'error_login_inactivo';
    }
    //SESION ACTIVA POR OTRA PERSONA
    else if (typeError == 3) {
      errorMessage = 'error_login_sesion_activa';
    }
    //VIGENCIA 
    else if (typeError == 4) {
      errorMessage = 'error_login_vigencia';
    }
    else {
      errorMessage = 'Ingresar datos nuevamente';
    }

    this.notificationService.smartMessageBox(
      {
        title: `<i class="fa fa-lock text-danger"></i>&nbsp;<span class='text-danger'><strong>` + 'error' + `</strong></span>`,
        content: errorMessage,
        buttons: '[Ok]'
      },
      buttonPressed => {
        if (buttonPressed == "Ok") {
        }
      }
    );

  }

 /* next() {
    this.stepper.next();
  }

  onSubmit() {
    return false;
  }

  showModalAuto(): void {
    
    this.isModalShown = true;
    console.log("---------------- entra auto modal");
   
  }
  hideModalAuto(): void {
    this.autoShownModal.hide();
  }*/

}