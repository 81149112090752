import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpWebService } from '../../app/core/http-web.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
//import { ConsoleReporter } from 'jasmine';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})
export class CatalogoComponent implements OnInit {
  isCollapsed = false;
  parametro: string = "";
  constructor(
    private _scrollToService: ScrollToService,
    private modalService: BsModalService,
    private route: Router,
    private rutaActiva: ActivatedRoute,
    private HttpWebService: HttpWebService
  ) { 
    this.rutaActiva.params.subscribe( parametros =>{
      this.parametro = parametros["parametro"];
      if (this.parametro  == 'ok') {
        this.parametro="";
      }
      
    })
  }

  //catalogo: any;
  pageActual: number = 1;
  pageSize: number = 9;
  customPageSize: number = 9;
  productos: any = [];
  //router: Router;
  maxSelectedProducts: number = 3;
  isShowingAll: boolean = false;
  selectedIDs: number[] = [];
  parametrosSeleccionados: string[] = [];

 
  ngOnInit() {
    localStorage.setItem('isstore', 'false');
    if (this.parametro==""){
      console.log("parametrosSeleccionados:");
      this.loadProducts(this.parametrosSeleccionados);
    }else{
      console.log("parametro:");
      this.loadProduct(this.parametro);
    }
    
    
  }

  loadProduct = (parametro:string)=>{
    /* conexion */
    this.HttpWebService.getNoHeaderAuthorization('crucero/buscador_zapatos/?' + parametro + '=1').then((response) => {
      this.productos = response.data.zapatos;
    })
  }

  loadProducts = (parametros:string[])=>{
    const params = this.formatearParametros(parametros);
    console.log("Parámetros: " + params);
    this.HttpWebService.getNoHeaderAuthorization('crucero/buscador_zapatos/' + params).then((response) => {
      //console.log(response);
      this.productos = response.data.zapatos;

      //console.log("++++++productos+++++++++", this.productos);
    })
  }
  formatearParametros = (parametros:string[])=>{
    if(parametros.length==0){
      return "";
    }else{
      return "?" + parametros.join("=1&") + "=1";
    }
  }
   btnClick() {
     if(this.selectedIDs.length>1){
      this.route.navigate(['/comparador',this.selectedIDs.toString()]);
     }
    
  };

  public triggerScrollTo() {
    
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
 
    this._scrollToService.scrollTo(config);
  }

  onChecked = (obj:{event:any, selectedID:number}) =>{
    //console.log(obj.event.target.checked + " " + obj.selectedID);

      if(obj.event.target.checked){
        this.selectedIDs.push(obj.selectedID)
      }
      else{
        const index = this.selectedIDs.indexOf(obj.selectedID);
        if (index > -1) {
          this.selectedIDs.splice(index, 1);
      }
    }

    //console.log("Número de Productos Seleccionados: " + this.selectedIDs.length);
  }

  onSelectedFilter = (obj:{event:any, selectedID:string}) =>{
    console.log(obj.selectedID + " " + obj.event.target.checked);
    this.pageActual = 1;
    if(obj.event.target.checked){
      this.parametrosSeleccionados.push(obj.selectedID);
    }else{
      const index = this.parametrosSeleccionados.indexOf(obj.selectedID);
      console.log("index: " + index)
      if (index > -1) {
        this.parametrosSeleccionados.splice(index, 1);
      }
    }
    console.log(this.parametrosSeleccionados);
    this.loadProducts(this.parametrosSeleccionados);
  }

  showAll = () =>{
    console.log('pageActual: ' + this.pageActual)
    if(this.isShowingAll){
      this.customPageSize = this.pageSize;
      //console.log(' this.pageSize ' + this.pageSize);
      
    }else{
      this.customPageSize = this.productos.length;
      //console.log(' this.parametrosSeleccionados ' + this.productos.length);
    }
    this.pageActual = 1;
    this.isShowingAll = !this.isShowingAll;
  }
}
