import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap';
import { HttpWebService } from '../../core/http-web.service';
import { AuthService } from '../../login/auth.service';
import { AlertService } from "../layout/alerts/alert.service";
import { NotificationService } from '../utils/notification.service';
import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-nav-icon-admin',
  templateUrl: './nav-icon-admin.component.html',
  styleUrls: ['./nav-icon-admin.component.css']
})
export class NavIconAdminComponent implements OnInit {
  public currentUser: any;
  public current: any;
  public users: any = [];
  public correoElectronico: any;
  public disabled: boolean = false;
  status: any;
  modalRef: BsModalRef | null;
  imagen: any;
  constructor(
    private HttpWebService: HttpWebService,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private modalService: BsModalService
  ) { }

  openModalEdit(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-dialog-45' })
    );
  }

  ngOnInit() {
    this._usuarios().then(rslt => {
      this.current = JSON.parse(localStorage.getItem('current'));
      this.imagen = JSON.parse(localStorage.getItem('imagen'));
      this.correoElectronico = JSON.parse(localStorage.getItem('correoElectronico'));
      this.status = JSON.parse(localStorage.getItem('status'));
      /*  this._sessionServer = localStorage.getItem('usuario'); */
      console.log("Obten el estatus:" + this.status);
      console.log('usuario actual:' + localStorage.getItem('current'));
      console.log(rslt);
      if (rslt) {

      }
      else {

      }
    });
    

    /*this._califica().then(rslt => {
      this.users = JSON.parse(localStorage.getItem('usuario'));
      //  this._sessionServer = localStorage.getItem('usuario'); 
      console.log(this.users);

      if (rslt) {

      }
      else {

      }
    });*/
  }
  logOut() {
   
    localStorage.removeItem('sessionServer');
    this.router.navigate(['login-user']);
  
}
  public setDataLocalStorageSession(_data: any): void {
  }

  public _califica(): Promise<any> {
    return this.HttpWebService.get('app/clientes');
  }

  guardar(current) {
    console.log(current);
    this.HttpWebService.post('app/cliente/actualizar', current).then((response) => {
      console.log('repsonse:', response);
      if (response.status === 200 && response.data.error) {
        this.alertService.hideProgressBar();
        this._errorResponse(5);
      }
      else {
        this.notificationService.successBigBox("Actualización de cliente exitosa.");
        /* this.router.navigateByUrl('/login'); */
        this.modalRef.hide();
        return response;
      }
    });
  }

  public _usuarios(): Promise<any> {
    return this.HttpWebService.get('app/clientes/');
  }

  private _errorResponse(typeError: number): void {
    let errorMessage: string = null;
    this.disabled = false;
    ///NO EXISTE EL USUARIO
    if (typeError == 1) {
      //this.isModalErrorConfirmationShow = true;
      errorMessage = 'error_login_no_existe_usuario';
    }
    //USUARIO INACTIVO
    else if (typeError == 2) {
      errorMessage = 'error_login_inactivo';
    }
    //SESION ACTIVA POR OTRA PERSONA
    else if (typeError == 3) {
      errorMessage = 'error_login_sesion_activa';
    }
    //VIGENCIA 
    else if (typeError == 4) {
      errorMessage = 'error_login_vigencia';
    }
    else {
      errorMessage = 'Ingresar datos nuevamente';
    }

    this.notificationService.smartMessageBox(
      {
        title: `<i class="fa fa-lock text-danger"></i>&nbsp;<span class='text-danger'><strong>` + 'error' + `</strong></span>`,
        content: errorMessage,
        buttons: '[Ok]'
      },
      buttonPressed => {
        if (buttonPressed == "Ok") {
        }
      }
    );

  }

}
