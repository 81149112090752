import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { InicioComponent } from './inicio/inicio.component';
import { NovedadesComponent } from './novedades/novedades.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { TiendaComponent } from './tienda/tienda.component';
import { ContactoComponent } from './contacto/contacto.component';
import { ComparadorComponent } from './comparador/comparador.component';


import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { SplashscreenComponent } from './splashscreen/splashscreen.component'

import { BsDropdownModule } from 'ngx-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpWebService } from './core/http-web.service';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './login/auth.service';
import { AuthServiceReg } from './register/auth.service';
import { AlertService } from './shared/layout/alerts/alert.service';

import { NgModule, ApplicationRef, LOCALE_ID } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from 'ngx-bootstrap';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { HttpErrorInterceptor } from './core/http-error.interceptor';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SidebarModule } from 'ng-sidebar';
import { EqualValidator } from './helpers/equal-validator.directive';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InterceptorModule } from './interceptor/interceptor.module';
import {
  SocialLoginModule, 
  AuthServiceConfig,
  GoogleLoginProvider, 
  FacebookLoginProvider, 
  LinkedinLoginProvider
} from 'ng-social-login-module';
import { LoginUserComponent } from './login-user/login-user.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { DatePipe } from '@angular/common';
import {FileUploadModule} from 'ng2-file-upload';

import { TabsModule } from 'ngx-bootstrap/tabs';

import { CuentasComponent } from './cuentas/cuentas.component';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { RecuperarPasswordComponent } from './recuperar-password/recuperar-password.component';
import { AngularFileUploaderModule } from "angular-file-uploader";

import { AgmCoreModule } from '@agm/core';
import { AdminComponent } from './admin/admin.component';

import {NgxPaginationModule} from 'ngx-pagination';
import { ProductComponent } from './product/product.component';
import { DetalleProductoComponent } from './detalle-producto/detalle-producto.component';
import { CartComponent } from './cart/cart.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxPayPalModule } from 'ngx-paypal';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    
    InicioComponent,
    NovedadesComponent,
    NosotrosComponent,
    CatalogoComponent,
    TiendaComponent,
    ContactoComponent,
    ComparadorComponent,


    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    SplashscreenComponent,
    LoginUserComponent,
    RegisterUserComponent,
    EqualValidator,

    CuentasComponent,
    RecuperarPasswordComponent,

    AdminComponent,
    ProductComponent,
    DetalleProductoComponent,
    CartComponent,
    UserProfileComponent
    
    ],
  imports: [
    NgxPayPalModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    SocialLoginModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    ScrollToModule.forRoot(),
    SidebarModule.forRoot(),
    NgxDatatableModule,
    DataTablesModule,
    DigitOnlyModule,
    BsDatepickerModule.forRoot(),
    FileUploadModule,
    TabsModule.forRoot(),
    InterceptorModule,
    PasswordStrengthMeterModule,
    AngularFileUploaderModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBGUnkeRxXDyrS8kejxodPfCZinZFcCleY'
    }),
    NgxPaginationModule,    
    
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    HttpWebService,
    AuthService,
    AuthServiceReg,
    AlertService,
   DatePipe
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
