import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { HttpWebService } from '../core/http-web.service';
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  isCollapsed = false;
  constructor(private _scrollToService: ScrollToService, private modalService: BsModalService,private HttpWebService: HttpWebService,private route: Router) { }

  destacados: any = [];
  novedades_array: any = [];
  novedad_single: any = "";
  ngOnInit() {
    this.loadDestacados();
    this.loadNovedades();
  }

  loadDestacados = ()=>{
    /* conexion */   
    this.HttpWebService.getNoHeaderAuthorization('crucero/consulta_destacados/').then((response) => {
      //console.log(response);
      this.destacados = response.data.destacados;
      
    })
  }

  loadNovedades = ()=>{
    /* conexion */   
    this.HttpWebService.getNoHeaderAuthorization('crucero/consulta_novedades/').then((response) => {
      //console.log(response);
      this.novedad_single = response.data.novedades_one;
      this.novedades_array = response.data.novedades_four;
      
    })
  }

  btnClick = (selectedID:number) =>{
    console.log("obj.selectedID: " + selectedID);
    this.route.navigate(['/novedades',selectedID]);
    
  };

  linkClick(parametro: string) {
    console.log("linkClick:");
     this.route.navigate(['/catalogo',parametro]);
  }

  public triggerScrollTo() {
    
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
 
    this._scrollToService.scrollTo(config);
  }

}
