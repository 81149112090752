import { Injectable } from '@angular/core';
import { AuthService } from '../login/auth.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import {} from 'rxjs/add/operator/toPromise';
import {} from 'rxjs/add/operator/map';
/* import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map'; */
import { DomSanitizer } from '@angular/platform-browser';


@Injectable()
export class HttpWebService {

  public pathRequestWS: string;
  static currentId: any;
  private messagesErrors: any = {
    400: 'La solicitud contiene sintaxis errónea y no debería repetirse.'
  };
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
  ) {

    //this.pathRequestWS = "http://127.0.0.1:9000/";
    //this.pathRequestWS = "https://bezapatos.fusion-ti.com/";
    this.pathRequestWS = "https://django.zapatoscrucero.com/";
  }
  public getHttpHeaders() {
    console.log("Token " + this.authService.getTokenAuth())
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', "Bearer " + this.authService.getTokenAuth())
      /*.append('Authorization', "CiciliAuth eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiJDaWNpbGlKV1QiLCJzdWIiOiJlcmlja2lkYWx5QGdtYWlsLmNvbSIsImF1dGhvcml0aWVzIjpbIlJPTEVfVVNFUiJdLCJpYXQiOjE1NTcyMDE4NDQsImV4cCI6MTU1NzIwNzg0NH0.sR18ka5MJ1McALCCx_HzAS9cST_raM3XGiHq4399gdJ8ubv5XsyUuzXOYHrcLvRJph-WMYgz4yG3YrkQa3NK0A")*/
      .append('Content-Language', localStorage.getItem('selectedLanguage') || 'es-MX')
  }
  
  public get(url: string): Promise<any> {
    url = this.pathRequestWS + url;
    return this.http.get(url, { headers: this.getHttpHeaders() })
      .toPromise()
      .then(
        (rslt: any) => {
          return rslt;
        })
      .catch(this.handleError);
  }


  public getNoHeaderAuthorization(url: string): Promise<any> {
    url = this.pathRequestWS + url;
    return this.http.get(url)
      .toPromise()
      .then(
        (rslt: any) => {
          return rslt;
        })
      .catch(this.handleError);
  }

  public post(url: string, body: any = {}): Promise<any> {
    url = `${this.pathRequestWS}${url}`;
    return this.http.post(url, JSON.stringify(body),{ headers: this.getHttpHeaders() })
      .toPromise()
      .then(
        (rslt: any) => {

          if (rslt.estatus== "ok" && rslt.data) {
            return rslt;
          }
          return rslt;
        })
      .catch(this.handleError);
  }

  public put(url: string, body: any): Promise<any> {
    url = `${this.pathRequestWS}${url}`;
    return this.http.put(url, JSON.stringify(body))
      .toPromise()
      .then(
        (rslt: any) => {
          return rslt.data;
        })
      .catch(this.handleError);
  }

  public patch(url: string, body: any): Promise<any> {
    url = `${this.pathRequestWS}${url}`;
    return this.http.patch(url, JSON.stringify(body))
      .toPromise()
      .then(
        (rslt: any) => {
          return rslt.data;
        })
      .catch(this.handleError);
  }

  public delete(url: string): Promise<any> {
    url = this.pathRequestWS + url;
    return this.http.delete(url)
      .toPromise()
      .then(
        (rslt: any) => {
          return rslt.data;
        })
      .catch(this.handleError);
  }

  private handleError(error: any): any {
    //console.error('An error occurred', error); // for demo purposes only
    return error;
  }

  public getHttpHeadersPdf() {
    return new HttpHeaders()
      .append('Content-Type', 'application/pdf')
      .append('Accept', 'text')
      .append('Authorization', this.authService.getTokenAuth())
      .append('Content-Language', localStorage.getItem('selectedLanguage') || 'es-MX')
  }

  public getPdf(url: string): Promise<any> {
    url = this.pathRequestWS + url;
    return this.http.get(url, { headers: this.getHttpHeadersPdf(), responseType: 'blob' as 'json' })
      .toPromise()
      .then(
        (rslt: any) => {
          return rslt;
        })
      .catch(this.handleError);
  }
  private _getResponseError(_status: number): any {
    return {
      Code: _status,
      Data: null,
      Error: true,
      Message: this.messagesErrors[_status]
    }
  }
}
