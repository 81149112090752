import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { Router, RoutesRecognized } from '@angular/router';
import { HttpWebService } from '../core/http-web.service';

import { AuthService } from '../login/auth.service';
import { CartService, Product } from '../services/cart/cart.service';
import { element } from 'protractor';
import { ModalService } from '../services/modal.service';
import { FormGroup, FormBuilder, FormArray, Validators, NgForm, AbstractControl, FormControl} from '@angular/forms';
import { AuthGuard } from './guards/auth.guard';

import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.css']
})
export class DetalleProductoComponent implements OnInit {

  @ViewChild('closeFacturacionModal') closeFacturacionModal;
  
  fondos: any[] = [];
  modelo:string = "";
  productos: any[] = [];
  productosRelacionados: any[] = [];
  productoActivo: any;
  cartProducts: any[] = [];
  colores:any[] = [];
  tallas:any[] = [];
  imagenSeleccionada:string;
  cantidad:number = 1;
  total:number = 0;
  totalItems:number = 0;
  items: FormArray;
  loading:boolean = true;
  disponibleActivo:boolean = false; 
  public isstore:boolean= false; 
  cotizaForm: FormGroup;
  fondoAleatorio:string="";

  _session: any;
  mayorista:boolean = false;

  textoTextArea: string="";
  
  constructor(private rutaActiva: ActivatedRoute,
              private httpWebService: HttpWebService,
              private fb: FormBuilder,
              private router: Router,
              private el: ElementRef,
              private modalService: ModalService,
              private authService: AuthService,
              private cartService:CartService ) {

              let valores: boolean;  
              var subject = new Subject<boolean>();
              
              
        
              subject.asObservable().subscribe((r)=> {
              this.changeIsStore(r);
              })      
              console.log("valores: " + valores);    

              

        this._session = authService.getSession();


        this.rutaActiva.params.subscribe( parametros =>{
          this.validateParams(parametros["modelo"]); 
        
        })
        this.createCotizaForm();
        if(this.modelo===""){

        }else{
          this.loadProduct(this.modelo);
        }
     }

  getRoute(): void{
    this.router.events.filter(e => e instanceof RoutesRecognized)
                .pairwise()
                .pipe(tap((event: any[]) => {

                  var splitted = event[0].urlAfterRedirects.split("/", 3);
                  
                  for (let elemento of splitted){
                    console.log("elemento: " + elemento); 
                    if (elemento == "tienda") {
                      localStorage.setItem('isstore', 'true');
                      break;
                    }
                    
                  }

                  }))
                .subscribe((event: any[]) => {
                  this.isstore = true;}
                  //subject.next(valores);}
                  );

                  if (localStorage.getItem('isstore') == 'true'){
                    this.isstore = true;
                    
                  }else{
                    this.isstore = false;
                  }
  }

  changeIsStore = (status:boolean) =>{
      this.isstore = status;
  }

  getIdUser = ()=>{
    let idUser: number = 0;
    let _localSession: any = localStorage.getItem('session');
    if (_localSession){
      _localSession = JSON.parse(CryptoJS.AES.decrypt(_localSession, "AGS").toString(CryptoJS.enc.Utf8));
      idUser = _localSession.userId;
    }
    return idUser;
  } 

  loadProduct = (modelo:string) =>{
    this.httpWebService.get('crucero/zapatos_para_venta/?code=' + this.getIdUser() +'&modelo=' + modelo).then((response) => {

      this.productos = response.data.zapatos;
      this.productosRelacionados = response.data.zapatos_coinciden;
      if(this.productos.length>0){
        this.procesaProductos();              
      }
      this.loading = false;
      console.log(this.productos);
    })
  }
  ngOnInit() {
    console.log("Iniciando");  
    this.scrollToTop();
    this.fondos = [];
    //this.fondos.push({"background":"background"});
    this.fondos.push({"background":"background1"});
    this.fondos.push({"background":"background2"});
    this.fondos.push({"background":"background3"});
    this.fondos.push({"background":"background4"});
    this.fondos.push({"background":"background5"});
    this.fondos.push({"background":"background6"});
    this.fondos.push({"background":"background7"});
    const id = Math.floor(Math.random() * this.fondos.length);
    console.log("Iniciando id: ",id);  
    this.fondoAleatorio = this.fondos[id].background;
    this.getRoute();
    console.log("Iniciando id: ",localStorage.getItem('isstore'));  
    
  }

  scrollToTop() {
    
    const element = this.el.nativeElement;
    //window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  procesaProductos = ()=>{
    this.cartProducts = [];
    this.colores = [];
    this.tallas = [];
    this.productoActivo = this.productos[0];

    this.cartProducts.push(this.createProduct(0,this.productoActivo));
    this.imagenSeleccionada = this.productoActivo.foto_principal;
    this.textoTextArea = " Quiero solicitar una cotización de: ";
    this.textoTextArea += " Modelo: " + this.productoActivo.modelo + " " + this.productoActivo.descripcion + "\n";
    this.productos.forEach(producto => {
        const one_color = { 'border':'solid','height': '40px','width': '60px','background-color':producto.one_hex_color,'background-image':'linear-gradient(62deg, '+producto.one_hex_color+' 50%,'+producto.two_hex_color +' 50%)'} //'background-color':producto.one_hex_color};
        this.colores.push({"productID":producto.id,"one_color":one_color,"two_color":producto.two_hex_color,"color":producto.color.toLowerCase(),"colorID":producto.color_id})
        this.tallas.push({"productID":producto.id,"tallas":producto.tallas});

        this.textoTextArea += "Color: " + producto.color.toLowerCase()+ "\n";
        this.textoTextArea += "Tallas    |   Cantidad \n";
        producto.tallas.forEach(talla => {
          this.textoTextArea += talla.talla + "         \n";
        });
        
    });

    if (this.productoActivo.disponible_web==""){
      this.disponibleActivo = false
    }else{
      this.disponibleActivo = true // debe ser true, lo cambié para pruebas de carrito
    }

    
    

  }

  validateParams = (param:string) =>{
    if(param===""){
      console.log("No se seleccionaron zapatos para comparar");
    }else{
      this.modelo = param;
    }
  }

  getPrecio() {
    if (this._session){
      if (this._session.userMayorista || this._session.userDistribuidor){
        return (this.productoActivo.precio_mayoreo)
      }else{
        return(this.productoActivo.precio_tienda)
      }
    }else{
      return(this.productoActivo.precio_tienda)
    }
    
  }
  getPrecioDescuento() {
    if (this._session){
      console.log("mayorista_distribuidor: " + this._session.tipo_usuario);
      if (this._session.mayorista_distribuidor){
        if (this._session.tipo_usuario=='DISTRIBUIDOR'){
          return (this.productoActivo.descuento_distribuidor.precio_con_descuento)
        }else{
          return (this.productoActivo.descuento_mayoreo.precio_con_descuento)
        }  
      }else{
        return(this.productoActivo.descuento_tienda.precio_con_descuento)
      }
    }else{
      return(this.productoActivo.descuento_tienda.precio_con_descuento)
    }
    
  }

  

  createProduct(index:number,product:any) {
    return {
    "index": index,
    "id": product.id,
    "foto": product.foto_principal,
    "colorID": product.color_id,
    "color": product.color.toLowerCase(),
    "talla": product.tallas[0].talla,
    "tallas": product.tallas,
    "cantidad": 1
    }
  }

  tallasProduct = (productID:number):Array<any> =>{
    let index = this.tallas.findIndex(element => element.productID == productID);
    return this.tallas[index].tallas;
  }

  cambiaProducto = (index:number) => {
    this.productoActivo = this.productos[index];
    if (this.productoActivo.disponible_web==""){
      this.disponibleActivo = false
    }else{
      this.disponibleActivo = true // debe ser true, lo cambié para pruebas de carrito
    }
    console.log("disponibleActivo: ", this.disponibleActivo);
    this.imagenSeleccionada = this.productoActivo.foto_principal;
  }

  cambiaFoto = (foto:string) => {
    this.imagenSeleccionada = foto;
  }
  
  
  addItem(): void {
      const product = this.cartProducts[this.cartProducts.length-1];
      this.cartProducts.push(this.createProduct(product.index+1, this.productoActivo));
    console.log(this.cartProducts);
  }

  cambiaTallas = (selectedValue:any,productIndex:any) =>{
    
    const productIdx = this.cartProducts.findIndex(product => product.index == productIndex);
    const product = this.cartProducts[productIdx];

    product.color = selectedValue;
    const colorIndex = this.colores.findIndex(element => element.color == selectedValue);
    
    const color = this.colores[colorIndex];
    
    product.id = color.productID;
    product.colorID = color.colorID
    const productIDIndex = this.tallas.findIndex(element => element.productID == product.id);
    product.tallas = this.tallas[productIDIndex].tallas;
    
  }
  
  addItemsToCart = (cartForm:NgForm) =>{
    if(this.authService.isSessionValid()){
      if(!cartForm.invalid){
        console.log("producto a agragar" + this.productoActivo);
        this.cartService.registerCart(this.cartProducts,this.productoActivo);
      }   
    }else{
      this.router.navigate(['/login']);
    } 
  }

  borrarLinea = (productIndex:number) =>{
    const index = this.cartProducts.findIndex(product => product.index == productIndex);
    this.cartProducts.splice(index,1);
    
  }

  createCotizaForm = () => {
    this.cotizaForm = this.fb.group({
      name:['',[Validators.required]],
      email: ['',[Validators.required, Validators.email]],
      state: ['',[Validators.required]],
      company: ['',[Validators.required]],
      phoneNumber: ['',[Validators.required,Validators.minLength(10), Validators.maxLength(10)]],
      message: ['',[Validators.required]]
    });
    
  }


  public registraCotizacion(): void {

    if(this.authService.isSessionValid()){
      if(this.cotizaForm.invalid){
        return this.showValidations(this.cotizaForm);
      }else{
        console.log(this.cotizaForm);
        this.httpWebService.post('crucero/registrar_cotizacion/', this.formatearParametros()).then(
        (_rslt:any) => {
          this.closeFacturacionModal.nativeElement.click();        
           console.log(_rslt);
          if (_rslt.estatus =="ok" && _rslt.data)  {
            this.modalService.setModalInfo("Mensaje Registrado",
              "Su cotización ha sido registrada, en la brevedad alguien se pondrá en contacto con usted.",true,
              "","","Aceptar","/",true);
    
            this.modalService.showModal();
          } 
          else {
            this.modalService.setModalInfo("Error al enviar su cotización",
            _rslt.mensaje,false,
              "","","Aceptar","",true);
            this.modalService.showModal();
          }
        },
        error => {
          this.closeFacturacionModal.nativeElement.click();      
          this.modalService.setModalInfo("Error al enviar su cotización",
          "Ocurrió un error al registrar la información",false,
              "","","Aceptar","",true);
          
              this.modalService.showModal();
          console.log("Error al registar la cotización", error);
         }
        );
      }  
    }else{
      this.router.navigate(['/login']);
    } 

  
  }
  formatearParametros=()=>{
    const parametros = {
      "nombre": this.cotizaForm.get("name").value,
      "email": this.cotizaForm.get("email").value,
      "ciudad_estado": this.cotizaForm.get("state").value,
      "empresa": this.cotizaForm.get("company").value,
      "telefono": this.cotizaForm.get("phoneNumber").value,
      "cotizacion": this.cotizaForm.get("message").value
    }
    return parametros;
    
  }
    
  
    showValidations = (form:FormGroup) =>{
      if(form.invalid){
        return Object.values( form.controls ).forEach( control => {
          control.markAsTouched();
        })
      }
    }
  
  //funcion de validos
invalidUserField = (field:string) =>{
  return (this.cotizaForm.get(field).invalid && this.cotizaForm.get(field).touched);
}

reloadCurrentRoute = (modelo:string) =>{  
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/detalle-producto',modelo]);
  });
}

verProducto = (modelo:string) =>{
    //console.log(modelo);
    this.loadProduct(modelo);
    //this.router.navigate(['/detalle-producto',modelo]);
    this.reloadCurrentRoute(modelo);
}
}