import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { HttpWebService } from '../../app/core/http-web.service';

@Component({
  selector: 'app-comparador',
  templateUrl: './comparador.component.html',
  styleUrls: ['./comparador.component.css']
})
export class ComparadorComponent implements OnInit {
  isCollapsed = false;
  selectedIDs: number[] = [];
  productos: any[] = [];
  message: string = "";

  @Input() isProductStore: boolean;

  constructor(
    private _scrollToService: ScrollToService, 
    private modalService: BsModalService, 
    private route: Router, 
    private location: Location, 
    private rutaActiva: ActivatedRoute,
    private HttpWebService: HttpWebService ) {
        this.rutaActiva.params.subscribe( parametros =>{
          this.validateParams(parametros["ids"]);
          
        })

     }

  
  ngOnInit() {
    /* conexion */

    if(this.selectedIDs.length>0){
      this.HttpWebService.get('crucero/comparar_zapatos/?zapatos=' + this.selectedIDs.toString() ).then((response) => {
        this.productos = response.data.zapatos;

        console.log(this.productos);
      })
    }
    //http://127.0.0.1:8000/crucero/comparar_zapatos/?zapatos=1,2
  }
  
  btnClick() {
    //this.route.navigate(['/catalogo']);
    this.location.back();
  };

  validateParams = (params:any) =>{
    if(params===""){
      this.message="Seleccione al menos dos productos para comparar";
      console.log("No se seleccionaron zapatos para comparar");
    }else{
      this.selectedIDs = params.split(',');
      const allAreIntegers = this.selectedIDs.every(function (e) {
        return !isNaN(e);
      });
      if(allAreIntegers){
        if(this.selectedIDs.length<2 || this.selectedIDs.length>3){
          this.message="Seleccione dos o tres productos para comparar";
          console.log("Seleccionar más de un artículo para comparar");
          this.selectedIDs = [];
        }else{
          console.log(this.selectedIDs);  
        }
      }else{
        this.selectedIDs = [];
        this.message="Los parámetros proporcionados son inválidos";
        console.log("Parámetros Inválidos");
      }
      
    }
  }

  public triggerScrollTo() {
    
    const config: ScrollToConfigOptions = {
      target: 'destination'
    };
 
    this._scrollToService.scrollTo(config);
  }

  verProducto = (modelo:string,disponible:string) =>{
    if (disponible != ''){
      this.isProductStore=true;
    }
    console.log("isProductStore: ", this.isProductStore)
    if(this.isProductStore){
      this.route.navigate(['/detalle-producto',modelo]);
    }
  }

}
