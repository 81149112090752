import { Component, OnInit, Input } from '@angular/core';
import { FadeZoomInTop } from "../../animations/fade-zoom-in-top.decorator";

@FadeZoomInTop()
@Component({
    selector: 'app-alert-progress-bar',
    template: `
    <div class="">
        <div class="alert alert-danger" role="alert">
        <i class="fa primary fa-fw {{data.iconTitle}}"></i> {{data.title}}. {{data.body}}
        </div>
    </div> 

  `,
    styles: []
})
export class AlertProgressBarComponent implements OnInit {

    @Input() data: {
        title?: string,
        body?: string
        iconTitle?: string,
        
    } = {
            title: 'guardando_cambios',
            body: 'procesando_informacion',
            iconTitle: 'fa-floppy-o',
        }


        
    constructor() { }

    ngOnInit() {
        
        // this.iconTitle = 'fa-floppy-o';
        // this.title = 'Guardando datos';
        // this.body = 'Se está procesando la información';
    }

}
