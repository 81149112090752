import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpWebService } from '../../app/core/http-web.service'
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../app/login/auth.service';
import { AlertService } from "../../app/shared/layout/alerts/alert.service";
import { NotificationService } from '../../app/shared/utils/notification.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  public user: string;
  public data: any;

  constructor(
    private HttpWebService: HttpWebService,
    private authService: AuthService,
    public http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    private notificationService: NotificationService,
  ) { }

  ngOnInit() {
  }

  public forgot(): void {
    this.authService.forgot(this.user).then(
      (_rslt: boolean | any) => {
        this.alertService.hideProgressBar();
        if (_rslt) {
          console.log('this.authService.login', _rslt);
          this.notificationService.successSmallBox("Se ha enviado un correo para reestablecer la contraseña", );
this.user = "";
          return _rslt;
        } else {

          this.alertService.showProgressBar('Error al enviar correo', 'fa-lock', 'Intente nuevamente.');
          setTimeout(() => {
            this.alertService.hideProgressBar();
          }, 3000);
        }
      },
      /*  error => {
         this.error = error;
         this.alertService.hideProgressBar();
         window.alert("credenciales incorrectas")
       } */
    );
  }
}
