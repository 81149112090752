import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";


import { TooltipModule, BsDropdownModule, AlertModule, BsDatepickerModule } from "ngx-bootstrap";

import { EmptyLayoutComponent } from './app-layouts/empty-layout.component';
import { AuthLayoutComponent } from './app-layouts/auth-layout.component';
import { PublicPageLayoutComponent } from './app-layouts/public-page-layout.component';
import { DefaultLayoutComponent } from './app-layouts/default-layout.component';
import { AdministradorLayoutComponent } from './app-layouts/administrador-layout.component';
import { AseguradoLayoutComponent } from './app-layouts/asegurado-layout.component';
import { RecursosHumanosLayoutComponent } from './app-layouts/recursos-humanos-layout.component';



import { LayoutSwitcherComponent } from "./layout-switcher.component";
import { AlertProgressBarComponent } from './alerts/alert-progress-bar.component';
import { ConfirmDeleteComponent } from './alerts/confirm-delete.component';


@NgModule({
  imports: [
    CommonModule,
   
    FormsModule,
    RouterModule,
    
    TooltipModule,
    BsDropdownModule,
    AlertModule,
    BsDatepickerModule.forRoot(),
 
  ],
  declarations: [
    DefaultLayoutComponent,
    AdministradorLayoutComponent,
    AseguradoLayoutComponent,
    RecursosHumanosLayoutComponent,
    PublicPageLayoutComponent,
    AuthLayoutComponent,
    
    LayoutSwitcherComponent,
    EmptyLayoutComponent,
    
    AlertProgressBarComponent,
    ConfirmDeleteComponent
  ],
  exports: [
   
    LayoutSwitcherComponent,
    AdministradorLayoutComponent,
    AlertProgressBarComponent,
    ConfirmDeleteComponent
  ]
})
export class SmartadminLayoutModule {

}
