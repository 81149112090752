import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/login/auth.service';
import { ModalService } from 'src/app/services/modal.service';
import { Router } from '@angular/router';
import { delay, map,  tap } from "rxjs/operators";

@Component({
  selector: 'app-nav-cliente',
  templateUrl: './navbar-cliente.component.html',
  styleUrls: ['./navbar-cliente.component.css']
})
export class NavbarClienteComponent implements OnInit {

  @Output() textFind = new EventEmitter<{event:any, texto:string}>(false);

  @Input() items:number = 0;
  userName : string = "";
  buscador : string = "";
  
  constructor(private authService: AuthService,
              public modalService: ModalService,
              private _router:Router) { }

  ngOnInit() {
    this.userName = this.authService.getUserName();    
  }

  viewProfile = () =>{
    if(this.authService.isSessionValid()){
      this._router.navigate(['/user-profile']);
    }else{
      this._router.navigate(['/login']);
    }
  }

  btnBuscar = (event: any) =>{
    if (this.buscador==""){
      this.buscador="all";
    }
    this._router.navigate(['/tienda/'+this.buscador]);
    this.textFind.subscribe(v => console.log('event emitter', v));
    this.textFind.emit({event, texto:this.buscador}); 
    this.buscador="";
    
    
  }

  onChecked = (event: any, id:string) =>{
    console.log(id + " " + (event.target.checked ? 1:0));
    //this.filtroSeleccionado.emit({event, selectedID:id});          
  }
}