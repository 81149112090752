import { AfterViewInit,Component, OnInit, OnDestroy, TemplateRef,ViewChild, QueryList, ViewChildren } from '@angular/core';
import { NavIconComponent } from '../../shared/nav-icon/nav-icon.component';

import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpWebService } from '../../app/core/http-web.service';
import { AuthService } from '../../app/login/auth.service';
import { AlertService } from "../../app/shared/layout/alerts/alert.service";
import { NotificationService } from '../../app/shared/utils/notification.service';
import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';
import { FunctionCall } from '@angular/compiler';
import { DomSanitizer, SafeUrl, ɵDomSanitizerImpl, SafeHtml } from '@angular/platform-browser';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {esLocale} from 'ngx-bootstrap/locale';
import { DataTableDirective } from 'angular-datatables';

//import { listLocales } from 'ngx-bootstrap/chronos';
defineLocale('es', esLocale);
declare var $;

@Component({
  selector: 'app-cuentas',
  templateUrl: './cuentas.component.html',
  styleUrls: ['./cuentas.component.css']
})
export class CuentasComponent implements AfterViewInit, OnInit, OnDestroy {


  public disabled: boolean = false;
  public _opened: boolean = true;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  

  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject<any>();
  

  public datosRFCCuenta: {
    idRazonSocial: number,
    titular: string,
    idBanco: number,
    cuenta: string,
    clabe: string,
  };

  public datosRFCCuentaActualizar: {
    id: number,
    idRazonSocial: number,
    titular: string,
    idBanco: number,
    cuenta: string,
    clabe: string,
  };


  public nombre_pais_rfc:string;
  public nombre_estado_rfc:string;
  public nombre_municipio_rfc:string;
  public nombre_asentamiento_rfc:string;

  public nombre_banco:string;



  public datosCurrent: {
    idConcesionario: number,
    nombre: string,
    descripcion: string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    imagen: string,
  };

  modalRef: BsModalRef | null;
  fechaNacimiento :Date;

  direccion: any;
  pais: any;
  bancos: any;
  tipos: any;
  selectedPais: any;
  estado: any;
  municipio: any;
  asentamiento: any;
  listadoRFC: any;
  listadoRFCCuenta: any;

  public direccionConsulta: any;
  public current: any;

  concesionarioPerfil: any;

  private _toggleSidebar() {
    this._opened = !this._opened;
  }
  public imagen: String = "";

  constructor(
    private HttpWebService: HttpWebService,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private localeService: BsLocaleService
    //public _DomSanitizer: DomSanitizer,
  ) {
    

    this.datosRFCCuenta= {
      idRazonSocial: 0,
      titular: "",
      idBanco: 0,
      cuenta: "",
      clabe: ""
    };

    this.datosRFCCuentaActualizar= {
      id:0,
      idRazonSocial: 0,
      titular: "",
      idBanco: 0,
      cuenta: "",
      clabe: ""
    };

    

    this.nombre_pais_rfc="";
    this.nombre_estado_rfc="";
    this.nombre_municipio_rfc="";
    this.nombre_asentamiento_rfc="";

    this.fechaNacimiento=new Date();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      destroy: true,
      paging:true,
    };

  }

  
  

  /* handle imagen como base64 */
  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.current.imagen = 'data:image/png;base64,' + btoa(binaryString);
    console.log(btoa(binaryString));
  }
  /* handle imagen como base64 */


  ngOnInit() {

    this.localeService.use('es');

    this.tipos = [{id:1,text:"Persona Física"},{id:2,text:"Persona Moral"}]

    console.log("fecha de nacimeinto: " + this.fechaNacimiento);
    this.current = JSON.parse(localStorage.getItem('current'));

    console.log(this.current);
    

    this._consultaRFC().then(rslt => {
      this.direccion = rslt.data;
      if (rslt.data) {
        this.listadoRFC = rslt.data;
        // Calling the DT trigger to manually render the table
        this.dtTrigger.next();        
      }
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      destroy: true,
      paging:true,
    };



    /* catalogo de paises estados ext */
    this._pais().then(rslt => {
      this.pais = rslt.data;
    });

    this._bancos().then(rslt => {
      this.bancos = rslt.data;
    });

  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  

  public guardarRFCCuenta() {
    this._rfcGuardarCuenta().then(rslt => {
      if (rslt) {
        this.notificationService.successSmallBox("Registro de Cuenta para RFC exitosamente");

        this._consultaRFCCuenta().then(rslt => {
          if (rslt.data) {
            this.listadoRFCCuenta = rslt.data;     
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          }

        });
        return rslt;
      } else {
        this.alertService.showProgressBar('Error al actualizar, llenar todos lo campos', 'fa-lock', 'Intente nuevamente.');
        setTimeout(() => {
          this.alertService.hideProgressBar();
        }, 3000);
      }
    });
  }

  public _rfcGuardarCuenta(): Promise<any> {
    return this.HttpWebService.post('app/concesionario/cuenta/agregar', this.datosRFCCuenta);
  }

  public _pais(): Promise<any> {
    return this.HttpWebService.get('app/catalogos/paises');
  }

  public _bancos(): Promise<any> {
    return this.HttpWebService.get('app/catalogos/bancos');
  }

  public _estado(paisId: number): Promise<any> {
    return this.HttpWebService.get('app/catalogos/estados/' + paisId);
  }

  public _municipio(estadoid: number): Promise<any> {
    return this.HttpWebService.get('app/catalogos/municipios/' + estadoid);
  }

  public _asentamiento(municipioid: number): Promise<any> {
    return this.HttpWebService.get('app/catalogos/asentamientos/' + municipioid);
  }

  public _consultaRFC(): Promise<any> {
    return this.HttpWebService.get('app/concesionario/rfc/obtener');
  }
  public _consultaRFCCuenta(): Promise<any> {
    return this.HttpWebService.get('app/concesionario/consulta/cuenta/'+this.datosRFCCuenta.idRazonSocial);
  }

  onChangeRFC(rfcId: number) {
  
    if (rfcId) {
      this._consultaRFCCuenta().then(rslt => {
        if (rslt.data.length >0) {
          this.listadoRFCCuenta = rslt.data; 
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });

        }else{
          this.listadoRFCCuenta = null;
          
          console.log ("listado de cuentas : " + this.listadoRFCCuenta);
        }
        
        

      });
    } 
  }

  onChangePais(paisId: number) {
    if (paisId) {
      this._estado(paisId).then(rslt => {
        this.estado = rslt.data;
        this.municipio = null;
        this.asentamiento = null;
      });
    } else {
      this.estado = null;
      this.municipio = null;
      this.asentamiento = null;
    }
  }

  onChangeEstado(estadoid: number) {
    if (estadoid) {
      this._municipio(estadoid).then(rslt => {
        this.municipio = rslt.data
        this.asentamiento = null;
      });
    } else {
      this.municipio = null;
      this.asentamiento = null;
    }
  }

  onChangeMunicipio(municipioid: number) {
    if (municipioid) {
      this._asentamiento(municipioid).then(rslt => {
        this.asentamiento = rslt.data;
      });
    } else {
      this.asentamiento = null;
    }
    
  }


  openModalEditRFCCuenta(template: TemplateRef<any>,id:number) {    
  
    this._rfcCuentaConsulta(id.toString()).then(rslt => {
      if (rslt.data){
        this.datosRFCCuentaActualizar.id=id;
        this.datosRFCCuentaActualizar.idRazonSocial=rslt.data.idRazonSocial;
        this.datosRFCCuentaActualizar.clabe=rslt.data.clabe;
        this.datosRFCCuentaActualizar.titular=rslt.data.titular;
        this.datosRFCCuentaActualizar.cuenta=rslt.data.cuenta;
        this.datosRFCCuentaActualizar.idBanco=rslt.data.idBanco;

        this.nombre_banco = rslt.data.banco;
      }
    });

    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-dialog-45' })
    );
  }

  actualizarRFCCuenta(){
    this._rfcCuentaActualizar().then(response => {      
      if ((response)) {
         
        this._consultaRFCCuenta().then(rslt => {
          this.direccion = rslt.data;
          if (rslt.data) {
            this.listadoRFCCuenta = rslt.data;
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              // Destroy the table first
              dtInstance.destroy();
              // Call the dtTrigger to rerender again
              this.dtTrigger.next();
            });
          }
        });
        this.notificationService.successSmallBox("Actualización de Cuenta de RFC exitosa");   
        this.modalRef.hide(); 
        
        return response;
      } else {
        this.alertService.showProgressBar('Error al actualizar, llenar todos lo campos', 'fa-lock', 'Intente nuevamente.');
        setTimeout(() => {
          this.alertService.hideProgressBar();
        }, 3000);
      }
    });
  }

  
  public _rfcCuentaActualizar(): Promise<any> {
    return this.HttpWebService.post('app/concesionario/cuenta/actualizar', this.datosRFCCuentaActualizar);
  }

  public _direccionConsulta(): Promise<any> {
    return this.HttpWebService.get('app/concesionario/direccion');
  }

  public _rfcConsulta(pID: string): Promise<any> {
    return this.HttpWebService.get('app/concesionario/rfc/id/'+pID);
  }

  public _rfcCuentaConsulta(pID: string): Promise<any> {
    return this.HttpWebService.get('app/concesionario/cuenta/id/'+pID);
  }

  public _usuarios(): Promise<any> {
    return this.HttpWebService.get('app/concesionarios/');
  }

  private _errorResponse(typeError: number): void {
    let errorMessage: string = null;
    this.disabled = false;
    ///NO EXISTE EL USUARIO
    if (typeError == 1) {
      //this.isModalErrorConfirmationShow = true;
      errorMessage = 'error_login_no_existe_usuario';
    }
    //USUARIO INACTIVO
    else if (typeError == 2) {
      errorMessage = 'error_login_inactivo';
    }
    //SESION ACTIVA POR OTRA PERSONA
    else if (typeError == 3) {
      errorMessage = 'error_login_sesion_activa';
    }
    //VIGENCIA 
    else if (typeError == 4) {
      errorMessage = 'error_login_vigencia';
    }
    else {
      errorMessage = 'Ingresar datos nuevamente';
    }

    this.notificationService.smartMessageBox(
      {
        title: `<i class="fa fa-lock text-danger"></i>&nbsp;<span class='text-danger'><strong>` + 'error' + `</strong></span>`,
        content: errorMessage,
        buttons: '[Ok]'
      },
      buttonPressed => {
        if (buttonPressed == "Ok") {
        }
      }
    );

  }

}