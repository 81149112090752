import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Console } from 'console';
import { Router } from '@angular/router';
import { AuthService } from '../login/auth.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  @Input() product: any;
  @Input() selectedIDs: number[];
  @Input() maxSelectedProducts: number;
  @Input() isProductStore: boolean;
  @Input() display_price: boolean;
  @Input() is_store: boolean;
  @Output() checked = new EventEmitter<{event:any, selectedID:number}>();
  ischecked: boolean = false;
  _session: any;
  display_descuento: boolean = false;
  constructor(private route: Router,private authService: AuthService) { 
    this._session = authService.getSession();
  }

  ngOnInit() {
    //console.log("Rendering ID: " + this.product.id + " " + this.selectedIDs)
    const index = this.selectedIDs.indexOf(this.product.id);
      if (index > -1) {
        this.ischecked = true;
      }
  }

  onChecked = (event: any, selectedID: number) =>{
    if(event.target.checked && this.selectedIDs.length>=this.maxSelectedProducts){
      event.preventDefault();
    }else{
      this.checked.emit({event, selectedID});
      this.ischecked = event.target.checked;
    }
  }

  verProducto = (modelo:string) =>{
    if(this.isProductStore){
      this.route.navigate(['/detalle-producto',modelo]);
    }
  }

  getPrecio(product:any) {
    if (this._session){
      if (this._session.mayorista_distribuidor){
        return (product.precio_mayoreo)
      }else{
        return(product.precio_tienda)
      }
    }else{
      return(product.precio_tienda)
    }
    
  }

  getPrecioDescuento(product:any) {
    if (this._session){
      console.log("mayorista_distribuidor: " + this._session.tipo_usuario);
      if (this._session.mayorista_distribuidor){
        if (this._session.tipo_usuario=='DISTRIBUIDOR'){
          return (product.descuento_distribuidor[0].precio_con_descuento)
        }else{
          return (product.descuento_mayoreo.precio_con_descuento)
        }  
      }else{
        return(product.descuento_tienda.precio_con_descuento)
      }
    }else{
      return(product.descuento_tienda.precio_con_descuento)
    }
    
  }
  exiteDescuento(product:any) {
    if (this._session){
      if (this._session.mayorista_distribuidor){
        if (this._session.tipo_usuario=='DISTRIBUIDOR'){
          return (product.descuento_distribuidor[0].porcentaje)
        }else{
          return (product.descuento_mayoreo.porcentaje)
        }  
      }else{
        return(product.descuento_tienda.porcentaje)
      }
    }else{
      return(product.descuento_tienda.porcentaje)
    }
    
  }

  getNombreDescuento(product:any) {
    if (this._session){
      if (this._session.mayorista_distribuidor){
        if (this._session.tipo_usuario=='DISTRIBUIDOR'){
          return (product.descuento_distribuidor[0].nombre_descuento)
        }else{
          return (product.descuento_mayoreo.nombre_descuento)
        }  
      }else{
        return(product.descuento_tienda.nombre_descuento)
      }
    }else{
      return(product.descuento_tienda.nombre_descuento)
    }
    
  }

}
