import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators,FormArray, NgForm } from '@angular/forms';

import { CartService, Cart,Product, Talla } from '../services/cart/cart.service';
import { DireccionService } from '../services/direccion.service';
import { ModalService } from '../services/modal.service';

import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { NgxPayPalModule } from 'ngx-paypal';
import { ThrowStmt } from '@angular/compiler';
import { AuthService } from '../../login/auth.service';
import { TemplateRef } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  @ViewChild('closeDireccionModal') closeDireccionModal;
  @ViewChild('closeDireccionModal_nueva') closeDireccionModal_nueva;
  @ViewChild('popoverContent') popoverContent: TemplateRef<any>;
  @ViewChild(PopoverDirective) popover: PopoverDirective;

  closePopover() {
    this.popover.hide();
  }
  
  cart : Cart;
  loading:boolean = true;
  loadingPredefinida:boolean = true;
  loadingDirecciones:boolean = true;
  direccion: any;
  direcciones: any;
  cotizacion:any;
  orden:any;
  detalle:any;
  direccionForm:FormGroup;
  editDireccion:boolean = false;
  bloquearCotizacion:boolean = true;
  bloquearPayPal:boolean = true;
  nombreDireccion: string = "Nueva";
  ckeckedpredeterminada:boolean = false;
  obsDireccion:string = "Debe configurar una dirección de envío predeterminada para poder realizar la cotización de envío."
  
  public payPalConfig ? : IPayPalConfig;

  public showPopover = false;
  public isPayPal = true

  constructor(
    private route: Router,
    public cartService: CartService,
    private _modalService: ModalService,
    public direccionService: DireccionService,
    private _fb:FormBuilder) { 
      this.cart = this.cartService.loadCart((result)=>{
        if(result){
          this.cart = result;
          this.createDireccionForm();
          /*this.cotizacion = this.cartService.generaCotizacion(this.cart,'',(result)=>{
            if(result){
              this.cotizacion = result;
              
            }else{
              
            }
            this.loading = false;
          });*/
        }else{
          
        }
        this.loading = false;
      });

      

      this.direccion = this.cartService.loadDireccionPredeterminada((result)=>{
        if(result.direccion.length>0){
          this.direccion = result.direccion[0];
          this.bloquearCotizacion=false;
          this.nombreDireccion = "Nueva";
          //this.ckeckedpredeterminada = false;
        }else{
          this.bloquearCotizacion=true;
          this.nombreDireccion = "Configurar";
          //this.ckeckedpredeterminada = true;
        }
        this.loadingPredefinida = false;
      });

      this.direcciones = this.direccionService.loadDirecciones((result)=>{
        if(result.direccion.length>0){
          this.direcciones = result.direccion;
          this.nombreDireccion = "Nueva";
          this.ckeckedpredeterminada = false;
        }else{
          this.nombreDireccion = "Configurar";
          this.ckeckedpredeterminada = true;
        }
      });
      this.loadingDirecciones = false;

      
      console.log("mayorista_distribuidor = " + this.cartService.getSession().mayorista_distribuidor);
        
      if (this.cartService.getSession().mayorista_distribuidor){
        this.isPayPal = false;
      }
      console.log("isPayPal = " + this.isPayPal);
      

    }

  ngOnInit() {
    this.initConfig();
    
  }

  public muestraPopover(): void {
    this.showPopover = true;
  }

  public oculpaPopover(): void {
    this.showPopover = false;
  }

  private initConfig(): void {
    this.payPalConfig = {
        currency: 'MXN',
        clientId: 'AbuwN9Zb1RQlBeK4YGl82m1VFCpLRrtSyJRyomrT1UZN5OnRJmxV_swQYvcQO6gmgZUM1h5zGJWb-Eiz',  //id de crucero= GHS85Y88NJHD2
        createOrderOnClient: (data) => < ICreateOrderRequest > {
            intent: 'CAPTURE',
            purchase_units: [{
                amount: {
                    currency_code: 'MXN',
                    value: this.cart.total.toString(),
                    breakdown: {
                        item_total: {
                            currency_code: 'MXN',
                            value: this.cart.total.toString()
                        }
                    }
                },
                items: [{
                    name: 'Enterprise Subscription',
                    quantity: '1',
                    category: 'DIGITAL_GOODS',
                    unit_amount: {
                        currency_code: 'MXN',
                        value: this.cart.total.toString(),
                    },
                }]
            }]
        },
        advanced: {
            commit: 'true'
        },
        style: {
            label: 'paypal',
            layout: 'vertical'
        },
        onApprove: (data, actions) => {
            console.log('onApprove - transaction was approved, but not authorized', data, actions);
            actions.order.get().then(details => {
                console.log('onApprove - you can get full order details inside onApprove: ', details);
                this.generaOrden(1,1,details);
            });

        },
        onClientAuthorization: (data) => {
            console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
            
        },
        onCancel: (data, actions) => {
            console.log('OnCancel', data, actions);


        },
        onError: err => {
            console.log('OnError', err);

        },
        onClick: (data, actions) => {
            console.log('onClick', data, actions);

        }
    };
}

onPaymentComplete(data) {
  console.log('Transaction approved AGS', data);
}

  generaCotizacion = () => {
    const result = this.cartService.generaCotizacion(this.cart.id,this.direccion.id,(result)=>{
      this.cart.total -= this.cart.envio 
      this.cart.envio = 0
      if(result!={}){
        this.cotizacion = result;
        //console.log("cotizacion: " + JSON.stringify(this.cotizacion ));
        this.cart.envio = result.amount;
        this.cart.total += result.amount;
        this.bloquearPayPal=false
      }
    });
  }

  eliminaProducto = (id:any) => {
    const result = this.cartService.eliminaProducto(id,(result)=>{
      if(result!={}){
        this.cart = result;
      }
    });
  }
  

  generaOrden = (estatusPago:number,fuentePago:number,referencia:any) => {
    this.orden= {"carrito":this.cart.id, 
    "direccion":this.direccion.id, 
    "shipment":JSON.stringify(this.cotizacion.shipments),
    "subtotal":this.cart.sub_total,
    "descuento":this.cart.descuento,
    "importe_envio":this.cart.envio,
    "importe":this.cart.total,
    "fuente_pago":fuentePago,
    "referencia_pago": referencia,
    "estatus_pago":estatusPago};

    const result = this.cartService.generaOrden(this.orden,(result)=>{
      console.log("result de orden: " + result);
      //if(result != null){
      this._modalService.setModalInfo("Crucero",
          "Se generó la orden correctamente ",false,
          "Aceptar","/tienda/all","","",false);
      this._modalService.showModal();
      //}
    });
  }

  get products(){
    return this.cart.products;
  }

  routeToStore = () =>{
    this.route.navigate(["/tienda/all"]);
  }

  changeQty = (productId:number, talla:string, cantidad:string, descuento:string) =>{
    console.log ("cadena= " + cantidad)
    if (cantidad !== null) {
      this.detalle = {"product_id":productId,"talla":talla,"cantidad":cantidad,"monto_descuento":descuento}
      console.log(" detalle de envio: " + productId + " " + talla + " " + cantidad)
      const result = this.cartService.modificaProducto(this.detalle,(result)=>{
        if(result!={}){
          this.cart = result;
        }
      });
      this.bloquearPayPal = true;
      this.cartService.calculateTotals(this.cart);
    }
    
  }

  guardar = (cartForm:NgForm) =>{
    console.log(cartForm);
  }

  changeAddress = (direccion:any) =>{
    this.direccion = direccion;
    this.cart.total -= this.cart.envio 
    this.cart.envio = 0
    this.bloquearPayPal=true;
    this.closeDireccionModal.nativeElement.click();
  }

  cambiaMunicipios = (selectedValue:number) => {
    if(selectedValue){
      //cambiar valor del municipio
      console.log(selectedValue);
      this.direccionService.cambiaMunicipios(selectedValue);  
    }
  }
  invalidDireccionField = (field:string) =>{
    return (this.direccionForm.get(field).invalid && this.direccionForm.get(field).touched);
  }

  showValidations = (form:FormGroup) =>{
    if(form.invalid){
      return Object.values(form.controls ).forEach( control => {
        control.markAsTouched();
      })
    }
  }

  guardarDireccion = () => {
    console.log(this.direccionForm)
    if(this.direccionForm.invalid){
      console.log("Formulario es inválido");
      console.log(this.direccionForm)
      return this.showValidations(this.direccionForm);
    }else{
      const result = this.direccionService.save(this.direccionForm,this.editDireccion, (error)=>{
        this.closeDireccionModal_nueva.nativeElement.click();
        this.bloquearCotizacion=false;
        this.nombreDireccion = "Nueva";
        
        if(!error){
          this.direccion = this.direccionService.direccion_guardada;
          this.direcciones = this.direccionService.loadDirecciones((result)=>{
            if(result){
              this.direcciones = result.direccion;
            }
          });
          this.cart.total -= this.cart.envio 
          this.cart.envio = 0
          this._modalService.setModalInfo("Información",
              "La Dirección se guardo correctamente",true,
              "","","Aceptar","",false);
              this._modalService.showModal();

        }else{
          this._modalService.setModalInfo("Información",
              "Ocurrió un error al guardar la información",false,
              "","","Aceptar","",false);
              this._modalService.showModal();
        }
      });
      
    }
  }

  createDireccionForm = (direccion?:any) => {
    console.log("Dirección: " + direccion);
    if(direccion){
      this.direccionForm = this._fb.group({
        nombre:[direccion.nombre,[Validators.required]],
        calle: [direccion.calle,[Validators.required]],
        numero: [direccion.numero,[Validators.required]],
        codigoPostal: [direccion.cp,[Validators.required]],
        colonia: [direccion.colonia,[Validators.required]],
        estado: [direccion.estado_id,[Validators.required, Validators.min(1)]],
        municipio: [direccion.municipio_id,[Validators.required, Validators.min(1)]],
        referencia: [direccion.referencia,[Validators.required]],
        predeterminada: [direccion.predeterminada] 
      });
      this.cambiaMunicipios(direccion.estado_id);
    }else{
      this.direccionForm = this._fb.group({
        nombre:['',[Validators.required]],
        calle: ['',[Validators.required]],
        numero: ['',[Validators.required]],
        codigoPostal: ['',[Validators.required]],
        colonia: ['',[Validators.required]],
        estado: [0,[Validators.required, Validators.min(1)]],
        municipio: [0,[Validators.required, Validators.min(1)]],
        referencia: ['',[Validators.required]],
        predeterminada: [this.ckeckedpredeterminada]        
      });
    }
  }

}
